import React, { useEffect, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import chevronB from "../../assets/ion_chevron-back.svg";
import FadeInTop from "../../Components/FadeInTop";
import city from "../../assets/living.png";
import LineChartSingle from "../../Components/LineChartSingle";
import pdf from "../../assets/pdf.svg";
import arrowOutward from "../../assets/arrowOutward.svg";
import ProjectDetails from "./ProjectDetails";
import Financails from "./Financails";
import Transactions from "./Transactions";
import Unpublish, { handleUnpublish } from "../../Components/UnpublishProject";
import plus from "../../assets/outline_plus.svg";
import AddUpdate, { handleAddUpdate } from "./AddUpdate";

function ProjectDetailsScreen() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Project details");
  const [prStatus, setPrStatus] = useState("progress");
  const location = useLocation();
  const data = location.state.data || {};
  const tabs = [
    { name: "Project details", delay: 0.2 },
    { name: "Financials", delay: 0.4 },
    { name: "Transactions", delay: 0.6 },
  ];

  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <>
      <Unpublish />
      <AddUpdate id={data.id} />
      <div className="dash-body">
        <SideNav activeTab="Projects" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div className="ph-text-sec">
              <div style={{ width: "100%" }}>
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="back"
                  style={{
                    all: "unset",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    cursor: "pointer",
                  }}
                >
                  <img src={chevronB} alt="" />
                  Back
                </button>
              </div>
              <h4 style={{ fontSize: 20, marginTop: 16 }}>{data.name}</h4>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <div
                onClick={() => {
                  handleUnpublish();
                }}
                className="unpublish"
              >
                Unpublish
              </div>
              <div
                className="lnp-btn"
                onClick={() => {
                  handleAddUpdate();
                }}
              >
                <img src={plus} alt="" /> Add update
              </div>
            </div>
          </div>
          <div className="tab-nav">
            {tabs.map((item, index) => {
              return (
                <FadeInTop delay={item.delay} duration={1}>
                  <button
                    className={`${activeTab === item.name ? "activeTab" : ""}`}
                    onClick={() => {
                      setActiveTab(item.name);
                    }}
                  >
                    {item.name}
                  </button>
                </FadeInTop>
              );
            })}
          </div>
          {activeTab === "Project details" ? (
            <ProjectDetails id={data.id} />
          ) : activeTab === "Financials" ? (
            <>
              <Financails id={data.id} />
            </>
          ) : (
            <>
              <Transactions id={data.id} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProjectDetailsScreen;
