import React from "react";
import envelop from "../../assets/envelop.gif";
import SecBtn from "../../Components/SecBtn";
import { useNavigate } from "react-router-dom";
import tick from "../../assets/tick.gif";

export default function UpdateAdded() {
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={tick} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Update added</h4>
        <p>
          Your project has been updated with the <br /> latest details. Keep up
          the great work!
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Go to projects"
          onPress={() => {
            navigate("/Project", { replace: true });
          }}
        />
      </div>
    </section>
  );
}
