import React, { useEffect, useState } from "react";
import vImgSmall from "../assets/vImgSmall.png";
import close from "../assets/close-circle.svg";
import ticket from "../assets/ticket.svg";
import calender from "../assets/date.svg";
import notCalender from "../assets/notCalender.svg";
import chevDown from "../assets/chev-down.svg";
import Input from "./Input";
import {
  GetHolderById,
  GetVoucherTransaction,
} from "../RequestHandlers/Voucher";
import { formatDate, formatDate3 } from "./formatDate";
import { calculateEndDate } from "./CalculateEndDate";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import emptyList from "../assets/empty-list.svg";

interface PProps {
  onClose?: any;
  id?: any;
  holderDetails?: any;
}
function VHistory({ onClose, id, holderDetails }: PProps) {
  // const [activeService, setActiveService] = useState(null);
  const [service, setService] = useState<any>(null);
  const [product, setProduct] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [dropActive1, setDropActive1] = useState(false);

  const [value, setValue] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [errors, setErrors] = useState({
    product: "",
    service: "",
    value: "",
    date: "",
    time: "",
  });
  const [history, setHistory] = useState<any>([]);
  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "product":
        return value.trim() === "" ? "Product is required" : "";
      case "services":
        return value.trim() === "" ? "Services is required" : "";
      case "value":
        return value.trim() === "" ? "Value is required" : "";
      case "date":
        // Match YYYY-MM-DD format
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (value.trim() === "") return "Date is required";
        return !dateRegex.test(value) ? "Invalid date format (YYYY-MM-DD)" : "";
      case "time":
        // Match HH:mm format (24-hour time)
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (value.trim() === "") return "Time is required";
        return !timeRegex.test(value) ? "Invalid time format (HH:mm)" : "";
      default:
        return "";
    }
  };
  const handleInputChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "product":
        setProduct(value);
        break;
      case "service":
        setService(value);
        break;
      case "value":
        setValue(value);
        break;
      case "date":
        let formattedValue = value;
        if (formattedValue) {
          // Remove non-numeric and non-dash characters
          formattedValue = formattedValue.replace(/[^0-9-]/g, "");
          // Add dash dynamically for formatting (only if length matches year/month positions)
          if (formattedValue.length === 4 && !formattedValue.includes("-")) {
            formattedValue += "-";
          } else if (formattedValue.length === 7 && formattedValue[5] !== "-") {
            formattedValue = formattedValue.slice(0, 7) + "-";
          }
          // Limit to 10 characters (YYYY-MM-DD)
          formattedValue = formattedValue.slice(0, 10);
        }

        setDate(formattedValue); // Update state with the new value
        break;
      case "time":
        let formattedTime = value;
        if (formattedTime) {
          formattedTime = formattedTime.replace(/[^0-9:]/g, ""); // Allow only numbers and colons
          if (formattedTime.length === 2 && !formattedTime.includes(":")) {
            formattedTime += ":"; // Add colon after hours
          }
          formattedTime = formattedTime.slice(0, 5); // Limit to HH:mm
        }

        setTime(formattedTime);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validateField(fieldName, value),
    }));
  };
  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      service &&
      product &&
      value &&
      date &&
      time
    );
  };
  const handleSubmit = async () => {
    console.log("form");
    console.log(errors);

    if (isFormValid()) {
      console.log("valid");

      // navigate("/VerifyEmailScreen", { state: { data1 } });
    }
  };

  const getHolderById = async () => {
    try {
      const res = await GetHolderById(1, 10, id);
      console.log("holder", res);
    } catch (error) {
      console.log(error);
    }
  };
  const getVoucherTransaction = async () => {
    try {
      const res = await GetVoucherTransaction(id, 1, 20);
      console.log("transaction", res.items);
      setHistory(res.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(holderDetails);

    getHolderById();
    getVoucherTransaction();
  }, []);

  return (
    <div className="v-history">
      <div className="d-card-nav">
        <div className="d-card-wrap1">
          <img
            src={holderDetails?.brandVoucher?.image}
            alt=""
            style={{ objectFit: "cover" }}
          />
          <div>
            <h4 className="h-n">
              {holderDetails?.user?.firstname} {holderDetails?.user?.lastname}
            </h4>
            <p
              style={{
                color: "#A7FC00",
                fontSize: 16,
                fontWeight: 400,
                marginLeft: 0,
              }}
            >
              {holderDetails?.brandVoucher?.addedValue}% added value
            </p>
          </div>
          <div className="pr-num" style={{ backgroundColor: "#343434" }}>
            {holderDetails?.code}
          </div>
        </div>
        <img
          src={close}
          alt=""
          onClick={onClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="v-history-body">
        <div className="vhis-body1">
          <div className="dd-details-cont">
            <h4>Voucher details</h4>
            <div
              className="dd-details-item"
              style={{ justifyContent: "space-between" }}
            >
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={ticket} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>Voucher Price</h4>
                  <p>${holderDetails?.value?.toLocaleString()} / Unit</p>
                </div>
              </div>
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={ticket} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>Total value spent</h4>
                  <p>${holderDetails?.totalSpent?.toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div
              className="dd-details-item"
              style={{ justifyContent: "space-between" }}
            >
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={calender} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>Start Date</h4>
                  <p style={{ color: "#C3B2F7" }}>
                    {formatDate3(holderDetails?.created_at)}
                  </p>
                </div>
              </div>
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={notCalender} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>End Date</h4>
                  <p style={{ color: "#BA2543" }}>
                    {calculateEndDate(
                      holderDetails?.brandVoucher?.created_at,
                      holderDetails.brandVoucher.duration
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="update-form">
            <div className="in-hold">
              <div
                className={`reg-file-cont drop-down ${
                  service !== null ? "focused" : ""
                }`}
                onClick={() => {
                  setDropActive(!dropActive);
                }}
                style={{
                  height: 62,
                  backgroundColor: "#0D0D0D",
                  borderWidth: errors.product ? 1 : 0,
                  borderColor: "#ba2543",
                }}
              >
                <label className="floating-label">Service</label>
                <div className="active-ind"></div>
                <div className="chev">
                  <img
                    src={chevDown}
                    alt=""
                    className={`${dropActive ? "rotate" : ""}`}
                  />
                </div>
              </div>
            </div>
            <div className="in-hold">
              <div
                className={`reg-file-cont drop-down ${
                  product !== null ? "focused" : ""
                }`}
                onClick={() => {
                  setDropActive1(!dropActive1);
                }}
                style={{
                  height: 62,
                  backgroundColor: "#0D0D0D",
                  borderWidth: errors.service ? 1 : 0,
                  borderColor: "#ba2543",
                }}
              >
                <label className="floating-label">Product</label>
                <div className="active-ind"></div>
                <div className="chev">
                  <img
                    src={chevDown}
                    alt=""
                    className={`${dropActive1 ? "rotate" : ""}`}
                  />
                </div>
              </div>
            </div>
            <div className="in-hold" style={{ marginBottom: 0 }}>
              <div className="inp">
                <Input
                  type="text"
                  label="Value"
                  value={value}
                  contClass="inp-input"
                  extraClass="inp-input"
                  onChange={(e) => handleInputChange("value", e.target.value)}
                  error={errors.value ? true : false}
                  contStyle={{ height: 64 }}
                />
              </div>
              <div className="inp">
                <Input
                  type="text"
                  label="Date"
                  contClass="inp-input"
                  extraClass="inp-input"
                  value={date}
                  onChange={(e) => handleInputChange("date", e.target.value)}
                  error={errors.date ? true : false}
                  contStyle={{ height: 64 }}
                />
              </div>
              <div className="inp">
                <Input
                  type="text"
                  contClass="inp-input"
                  label="Time"
                  extraClass="inp-input"
                  value={time}
                  onChange={(e) => handleInputChange("time", e.target.value)}
                  error={errors.time ? true : false}
                  contStyle={{ height: 64 }}
                />
              </div>
            </div>
          </div>
          <div className="std-cont" style={{ marginTop: 24 }}>
            <div className="std-btn">Report issue</div>
            <button
              className={`c-btn ${!isFormValid() ? "btn-disabled" : ""}`}
              onClick={() => {
                handleSubmit();
              }}
              disabled={!isFormValid()}
            >
              Update
            </button>
          </div>
        </div>
        <div className="vhis-body1 vhis-body2">
          <p className="vhis-hText">Voucher history</p>
          {history.length === 0 ? (
            <div className="empty-wrap" style={{height: 400}}>
              <img src={emptyList} alt="" />
              <p>{"No voucher history"} </p>
              <span>The voucher history will appear here</span>
            </div>
          ) : (
            <>
              <div className="custom-th-wrap">
                <div className="custom-th">Service/Product</div>
                <div className="custom-th">Value spent</div>
                <div className="custom-th">Date</div>
              </div>
              <div className="vHistries-holder">
                {history.map((item: any, index: number) => {
                  return (
                    <div
                      className="custom-td-wrap"
                      style={{ marginTop: index === 0 ? 0 : 8 }}
                    >
                      <div className="custom-td" style={{ paddingRight: 10 }}>
                        {item?.description}
                      </div>
                      <div className="custom-td">
                        ${item?.amount?.toLocaleString()}
                      </div>
                      <div className="custom-td">
                        {formatDate(item?.created_at)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VHistory;
