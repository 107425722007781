import React, { useState, useEffect, useRef } from "react";
import HeaderBar from "../Components/HeaderBar";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../Components/Input";
import FadeInAnimation from "../Components/FadeIn";
import SecBtn from "../Components/SecBtn";
import TextArea from "../Components/Textera";
import chevDown from "../assets/chev-down.svg";
import roundedCheck from "../assets/rounded-check.svg";
import trash from "../assets/trash.svg";
import doc from "../assets/doc.svg";
import Toast, { handleToast } from "../Components/Toast";
import ActivityIndicator from "../Components/ActivityIndicator";
import { GetIndustries } from "../RequestHandlers/Auth";

export default function SignUpScreen2() {
  const cloud_name = "daqjecxhy";
  const cloud_key = "868378813212551";
  const preset_name = "stable_blocks";
  const navigate = useNavigate();
  const [compName, setCompName] = useState("");
  const [phone, setPhone] = useState("");
  const [abt, setAbt] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadedFile, setUpLoadedFile] = useState<any>(null);
  const [selectedIndustry, setSelectedIndustry] = useState<any>(null);
  const [uploading, setUpLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [dropActive, setDropActive] = useState(false);
  const [errors, setErrors] = useState({
    compName: "",
    abt: "",
  });
  const location = useLocation();
  const data1 = location.state?.data1 || "";
  // const industries = [
  //   { name: "Infrastructure" },
  //   { name: "Real estate" },
  //   { name: "Entertainment" },
  //   { name: "Transportation" },
  //   { name: "Technology" },
  //   { name: "Sports" },
  // ];
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [industries, setIndustries] = useState<any>(null)
  const fileInputRef = useRef(null);

  const uploadFile = async (file: any) => {
    setUpLoading(true); // Show loader
    setUploadError(null);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_name);
    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloud_name}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error?.message || "Failed to upload");
      }
      // if (data) {
      //   setUpLoading(false)
      // }
      setUpLoadedFile(data.secure_url);
      setSelectedFile(data);
      console.log("Uploaded file URL:", data);
    } catch (err: any) {
      setUploadError(err.message || "Something went wrong");
      handleToast(err.message, "error");
      console.error("Error uploading file:", err);
    } finally {
      setUpLoading(false); // Hide loader
    }
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("Selected file:", file);
      await uploadFile(file);
    }
  };

  const handleUploadClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };

  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "compName":
        return value.trim() === "" ? "Company Name is required" : "";
      case "abt":
        return value.trim() === "" ? "About company is required" : "";
      default:
        return "";
    }
  };

  const handleInputChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "compName":
        setCompName(value);
        break;
      case "abt":
        setAbt(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validateField(fieldName, value),
    }));
  };
  const isFormValid = () => {
    console.log("Errors:", errors);
    console.log("CompName:", compName);
    console.log("Abt:", abt);
    console.log("Selected File:", selectedFile);
    console.log("Selected Industry:", selectedIndustry);
    return (
      Object.values(errors).every((error) => error === "") &&
      compName &&
      abt &&
      selectedFile &&
      activeIndustry
    );
  };
  const handleSubmit = async () => {
    if (isFormValid()) {
      const data2 = {
        compName: compName.trim(),
        abt: abt.trim(),
        file: uploadedFile,
        activeIndustry: activeIndustry.id,
      };
      navigate("/CreatePasswordScreen", { state: { data1, data2 } });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getIndustries = async () => {
    try {
      const res = await GetIndustries();
      console.log(res);
      setIndustries(res)
    } catch (error) {
      console.log(error);
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = (event: MouseEvent) => {
    // Check if the clicked element is not inside the dropdown
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropActive(false);
    }
  };

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    if (dropActive) {
      // Add event listener when dropdown is active
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      // Clean up event listener to avoid memory leaks
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropActive]);
  return (
    <>
      <Toast />
      <div className="tk-reg-body">
        <HeaderBar
          onclick={() => {
            navigate(-1);
          }}
          showProgress={true}
          progress={66.6}
          progressText="2/3"
        />
        <div className="pageCont">
          <FadeInAnimation delay={0.3} duration={1}>
            <h4 className="pageH4">List your project</h4>
          </FadeInAnimation>
          <FadeInAnimation delay={0.6} duration={1}>
            <p className="pageP">
              This should only take a few minutes. Please note that you can only
              list a project
              <br />
              you’ve already tokenized with us.
            </p>
          </FadeInAnimation>

          <div className="inputCont1 input-cont">
            <FadeInAnimation delay={0.9} duration={1}>
              <Input
                type="text"
                label="Company Name"
                value={compName}
                onChange={(e) => {
                  handleInputChange("compName", e.target.value);
                }}
                error={errors.compName ? true : false}
                errorText={errors.compName}
              />
            </FadeInAnimation>
          </div>
          <div className="inputCont1">
            <FadeInAnimation delay={1.2} duration={1}>
              <div
                className={`reg-file-cont ${
                  selectedFile !== null ? "focused1" : ""
                }`}
              >
                <label className="floating-label1">
                  Company Registration
                </label>
                {selectedFile && (
                  <img src={roundedCheck} className="rounded-check" />
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
                <div></div>
                {selectedFile === null && uploading === false && (
                  <div className={`upload-btn`} onClick={handleUploadClick}>
                    Upload file
                  </div>
                )}
                {uploading === true && (
                  <ActivityIndicator size="24px" color="#a7fc00" />
                )}
                {selectedFile && (
                  <div className="file-holder">
                    <div className="holding-content">
                      <div className="fileName-cont">
                        <img src={doc} alt="" className="doc-img" />
                        {selectedFile?.original_filename}.{selectedFile?.format}
                      </div>
                      <img
                        src={trash}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedFile(null);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </FadeInAnimation>
          </div>
          <div className="inputCont1">
            <FadeInAnimation delay={1.2} duration={1}>
              <div
                className={`reg-file-cont drop-down ${
                  activeIndustry !== null ? "focused" : ""
                }`}
                onClick={() => {
                  setDropActive(!dropActive);
                }}
              >
                <label className="floating-label">Industry</label>
                <div className="active-ind">{activeIndustry?.name}</div>
                <div className="chev">
                  <img src={chevDown} alt="" />
                </div>
              </div>
            </FadeInAnimation>

            {dropActive && (
              <div className="in-drop-down">
                {industries.map((item:any, index:number) => {
                  return (
                    <div
                    key={index}
                      className={`ind ${
                        item === activeIndustry ? "ind-active" : ""
                      }`}
                      onClick={() => {
                        setActiveIndustry(item);
                        setDropActive(false);
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="inputCont1">
            <FadeInAnimation delay={1.5} duration={1}>
              <TextArea
                height={72}
                extraClass="textarea textarea1"
                label="About Company"
                value={abt}
                onChange={(e) => {
                  handleInputChange("abt", e.target.value);
                }}
                error={errors.abt ? true : false}
                errorText={errors.abt}
              />
            </FadeInAnimation>
          </div>
          <div className="bottomCont">
            <FadeInAnimation delay={1.8} duration={1}>
              <SecBtn
                text="Continue"
                onPress={handleSubmit}
                disabled={!isFormValid()}
              />
            </FadeInAnimation>
          </div>
        </div>
      </div>
    </>
  );
}
