import React from "react";
import envelop from "../assets/envelop.gif";
import SecBtn from "../Components/SecBtn";
import { useNavigate } from "react-router-dom";
import tick from "../assets/tick.gif";

export default function AccountCreatedScreen() {
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={tick} alt="" className="envelop" />
      <div className="textWrap">
        <h4>You Did It </h4>
        <p>
          You have successfully created an
          <br />
          account.
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Go to dashboard"
          onPress={() => {
            navigate("/Dashboard", { replace: true });
          }}
        />
      </div>
    </section>
  );
}
