import React, { useEffect, useState, useMemo } from "react";
import emptyList from "../../assets/empty-list.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/b-arrow-right.svg";
import { GetCollectionTransaction } from "../../RequestHandlers/Collection";
import { formatDate } from "../../Components/formatDate";

interface PProps {
  id: number;
}

function Transactions({ id }: PProps) {
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Fixed items per page
  const [status, setStatus] = useState("all");
  const [meta, setMeta] = useState<any>({ totalPages: 0 });
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getCollectionTransactions = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await GetCollectionTransaction(page, limit, status, id);
      setData(res.items);
      setMeta(res.meta);
    } catch (error) {
      setError("Failed to fetch transactions. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCollectionTransactions();
  }, [page]);

  const handleNext = () => {
    if (page < meta.totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== page) setPage(pageNumber);
  };

  const paginationPages = useMemo(() => {
    const totalPages = meta.totalPages || 1;
    const pages = [];
    const isNearStart = page <= 3;
    const isNearEnd = page >= totalPages - 3;

    if (isNearStart) {
      for (let i = 1; i <= Math.min(3, totalPages); i++) {
        pages.push(i);
      }
      if (totalPages > 6) pages.push("...", totalPages - 1, totalPages);
    } else if (isNearEnd) {
      pages.push(1, "...");
      for (let i = totalPages - 4; i <= totalPages; i++) {
        if (i > 0) pages.push(i);
      }
    } else {
      pages.push(1, "...");
      for (let i = page - 1; i <= page + 1; i++) {
        pages.push(i);
      }
      pages.push("...", totalPages);
    }

    return pages;
  }, [page, meta.totalPages]);

  return (
    <div className="v-holder new-hold-height">
      {loading ? (
         <div
         style={{
           width: "100%",
           height: 500,
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
         }}
       >
         <div className="dot-loader">
           <span className="dot"></span>
           <span className="dot"></span>
           <span className="dot"></span>
         </div>
       </div>
      ) : error ? (
        <div className="error">{error}</div>
      ) : data.length === 0 ? (
        <div className="empty-wrap empty-wrap-height">
          <img src={emptyList} alt="Empty List Icon" />
          <p>No transaction available</p>
          <span>List of transactions will appear here</span>
        </div>
      ) : (
        <>
          <table className="activity-table" style={{ marginTop: 0 }}>
            <thead>
              <tr>
                <th className="th-first">Name</th>
                <th>Date</th>
                <th>Unit price</th>
                <th>Units purchased</th>
                <th>Total value</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div
                      className="tr"
                      style={{ cursor: "pointer", paddingLeft: 20, height: 72 }}
                    >
                      <img
                        src={item?.user?.avatar}
                        alt={`${item?.user?.firstname} Avatar`}
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                        }}
                      />
                      <div className="tr-text">
                        <p>
                          {item?.user?.firstname} {item?.user?.lastname}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tr1 tr2">{formatDate(item.created_at)}</div>
                  </td>
                  <td>
                    <div className="tr1 tr2">
                      ${item?.amount?.toLocaleString()}
                    </div>
                  </td>
                  <td>
                    <div className="tr1 tr2">
                      {(item?.totalAmount / item?.amount).toFixed(0)}
                    </div>
                  </td>
                  <td>
                    <div className="tr-last1">
                      ${item?.totalAmount?.toLocaleString()}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {meta.totalPages > 1 && (
            <div className="pagination-cont">
              <button
                onClick={handlePrevious}
                disabled={page === 1}
                aria-label="Previous Page"
                className={page === 1 ? "disabled" : ""}
              >
                <img src={arrowLeft} alt="Previous" /> Previous
              </button>
              <div className="pagination-count">
                {paginationPages.map((p, index) =>
                  typeof p === "number" ? (
                    <p
                      key={index}
                      onClick={() => handlePageClick(p)}
                      className={page === p ? "pag-active" : ""}
                      style={{
                        cursor: "pointer",
                        color: page === p ? "#fff" : "",
                      }}
                    >
                      {p}
                    </p>
                  ) : (
                    <p key={index} className="ellipsis">
                      {p}
                    </p>
                  )
                )}
              </div>
              <button
                onClick={handleNext}
                disabled={page === meta.totalPages}
                aria-label="Next Page"
                className={page === meta.totalPages ? "disabled" : ""}
              >
                Next <img src={arrowRight} alt="Next" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Transactions;
