import React from "react";

interface PProps {
  text?: string;
  onPress?: any;
}
export default function PrimaryBtn({ text, onPress }: PProps) {
  return (
    <div className="primaryBtn" onClick={onPress}>
      <p>{text}</p>
    </div>
  );
}
