import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Chart, // Import Filler for background effect
} from "chart.js";

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Register Filler for background effect
);

interface chartProps {
  backendData?: any;
  backgroundDisabld?: boolean;
}

const LineChart = ({ backendData, backgroundDisabld }: chartProps) => {
  Chart.defaults.font.family = "Figtree";

  // Extract and sort unique dates
  const extractAndSortDates = () => {
    const datesSet = new Set();
    backendData.forEach((project: any) =>
      project.data.forEach((entry: any) => datesSet.add(entry.date))
    );

    // Convert to array and sort chronologically
    return Array.from(datesSet).sort(
      (a: any, b: any) => new Date(a).getTime() - new Date(b).getTime()
    );
  };

  const buildDatasets = (sortedDates: any) => {
    return backendData.map((project: any, index: number) => {
      // Create a map of date -> amount for the current project
      const dataMap = Object.fromEntries(
        project.data.map((entry: any) => [entry.date, entry.amount])
      );

      // Fill missing dates with 0
      const data = sortedDates.map((date: any) => dataMap[date] || 0);

      return {
        label: project.projectName,
        data,
        borderColor: ["#683FEA", "#A7FC00", "#FC7900", "#FFD700", "#C3B2F7"][
          index % 5
        ],
        backgroundColor: backgroundDisabld
          ? "transparent"
          : backendData.length === 1
          ? "rgba(104, 63, 234, 0.1)"
          : "transparent",
        borderWidth: 1.5,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 5,
        fill: true,
      };
    });
  };

  const sortedDates = extractAndSortDates(); // Get sorted unique dates
  const datasets = buildDatasets(sortedDates); // Create datasets

  // Chart data
  const data = {
    labels: sortedDates, // Use sorted dates as X-axis labels
    datasets,
  };
  const findMaxValue = (datasets: any[]) => {
    const allValues = datasets.flatMap((dataset) => dataset.data);
    const maxValue = Math.max(...allValues);
    return Math.ceil(maxValue / 1000) * 1000; // Round up to the nearest 1000
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#A5A5A5",
          font: {
            size: 14,
            weight: "400",
          },
        },
        grid: {
          display: false,
        },
        padding: 30,
      },
      y: {
        ticks: {
          color: "#A5A5A5",
          font: {
            size: 14,
            weight: "400",
          },
          callback: function (value: any) {
            return value / 1000 + "k"; // Format Y-axis ticks (e.g., 2k, 1k)
          },
          stepSize: 1000,
        },
        min: 0,
        max: findMaxValue(datasets), // Dynamically calculate max value
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
  };

  return (
    <div style={{ height: 140, width: "100%" }}>
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
