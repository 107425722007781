import React from "react";
import envelop from "../../assets/envelop.gif";
import SecBtn from "../../Components/SecBtn";
import { useNavigate } from "react-router-dom";
import tick from "../../assets/tick.gif";

export default function ProjectListed() {
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={tick} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Listing sent</h4>
        <p>
          Project creation complete, pending
          <br />
          admin approval for listing.
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Go to projects"
          onPress={() => {
            navigate("/Project", { replace: true });
          }}
        />
      </div>
    </section>
  );
}
