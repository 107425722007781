import "./style.css";
import profileIcon from "../../assets/profile-icon-circle.svg";
import groupIcon from "../../assets/group-icon-circle.svg";
import messageIcon from "../../assets/message-icon-circle.svg";
import keyIcon from "../../assets/key-icon-circle.svg";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { GetUserDetails } from "../../RequestHandlers/User";

interface ListProps {
  title: string;
  icon: string;
  route: string;
  setOpenProf: (open: boolean) => void;
}

interface UserDetails {
  avatar?: string;
  contactEmail?:string;
  name?:string

}

interface ProfileDropDownProps {
  setOpenProf: (open: boolean) => void;
  openProf: boolean;
}

const routes = [
  {
    title: "Personal information",
    icon: profileIcon,
    route: "profile-information",
  },
  {
    title: "Update password",
    icon: keyIcon,
    route: "password",
  },
  {
    title: "Notifications",
    icon: messageIcon,
    route: "notification-preference",
  },
  {
    title: "Currency preference",
    icon: groupIcon,
    route: "currency-preference",
  },
];

const ProfileDropDown = ({ setOpenProf, openProf }: ProfileDropDownProps) => {
  const [details, setDetails] = useState<UserDetails>({});
  const dropdownRef = useRef<HTMLDivElement | null>(null); 

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        setDetails(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenProf(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenProf]);

  return (
    <div
      style={{
        display: openProf ? "block" : "none",
      }}
      className="profile-drop-down-container"
      ref={dropdownRef} // Attach the ref to the dropdown container
    >
      <div className="profile-info-view">
        <div className="profile-image-view">
          {details.avatar && (
            <img src={details.avatar} alt="profile-avatar" className="profile-image" />
          )}
        </div>

        <div className="profile-user-info-view">
          <p className="profile-name">{details?.name}</p>
          <p className="profile-email">{details?.contactEmail}</p>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        {routes.map(({ title, icon, route }, index) => (
          <ProfileList
            setOpenProf={setOpenProf}
            route={route}
            key={index}
            title={title}
            icon={icon}
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileDropDown;

const ProfileList = ({ title, icon, route, setOpenProf }: ListProps) => {
  const navigate = useNavigate();

  return (
    <div className="profile-list-view">
      <div
        onClick={() => {
          navigate(`/ProfileScreen/${route}`);
          setOpenProf(false);
        }}
        className="profile-list-container"
      >
        <img src={icon} alt="icon" />
        <p className="profile-route">{title}</p>
      </div>
    </div>
  );
};
