import { useEffect, useState } from "react";
import HeaderBar from "../../Components/HeaderBar";
import ProfileTabHeader from "../../Components/profile/profile-tab-navigator.component";
import ProfileNav from "../../Components/ProfileNav";
import SideNav from "../../Components/SideNav";
import "../../css/style.css"
import "../../css/profile/styles.css"
import { useParams } from "react-router-dom";
import ProfileInformation from "../../Components/profile/profile-information.component";
import ProfilePassword from "../../Components/profile/profile-password.component";
import NotificationPreference from "../../Components/profile/notification-preference.component";
import CurrencyPreference from "../../Components/profile/currency-preference.component";
const ProfileScreen = () => {
    const [activeOption, setActiveOption] = useState<string | undefined>("profile-information")
    const { tab } = useParams();
    useEffect(() => {
        if (tab !== null) {
            setActiveOption(tab)
        }
    }, [tab])

    const renderTab = () => {
        switch (activeOption) {
            case "profile-information":
                return <ProfileInformation />
                break;
            case "password":
                return <ProfilePassword />
                break;
            case "notification-preference":
                return <NotificationPreference />
                break;
            case "currency-preference":
                return <CurrencyPreference />
                break;

            default:
                return <ProfileInformation />
                break;
        }
    }
    return (
        <>
            <div style={{
                paddingBottom: 119
            }} className="dash-body" >
                <SideNav activeTab="Dashboard" />
                <div className="content-body">
                    <ProfileNav />
                    <HeaderBar />
                    <div style={{
                        width: "100%",
                        marginTop: 20
                    }} >
                        <h1 className="profile-header-title" >My Profile</h1>
                    </div>


                    <div className="profile-tab-container" >
                        <ProfileTabHeader activeOption={activeOption} setActiveOption={setActiveOption} />

                        <div style={{
                            marginTop: 5,
                            width: "100%"
                        }}>
                            {renderTab()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfileScreen;