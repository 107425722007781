export function formatDate(dateString: any) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}` || "...";
}
export const formatDate2 = (isoDate: any) => {
  const date = new Date(isoDate);

  const options = { day: "2-digit", month: "long", year: "numeric" };
  // @ts-ignore
  return date.toLocaleDateString("en-GB", options) || "...";
};

export function formatDate3(dateString: string): string {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);

  const month = months[date.getMonth()];
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month} ${day}, ${year}` || "...";
}
