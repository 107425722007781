import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import chevronB from "../../assets/ion_chevron-back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import verse from "../../assets/verse.png";
import ValuesCard from "../../Components/ValuesCard";
import calender from "../../assets/date.svg";
import bTicket from "../../assets/b-ticket.svg";
import cashReq from "../../assets/cashReq.svg";
import dolls from "../../assets/dolls.svg";
import dropdownicon from "../../assets/icon-park_down.svg";
import LineChart from "../../Components/LineChart";
import emptyChart from "../../assets/empty-chart.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/b-arrow-right.svg";
import mClose from "../../assets/close.svg";
import copy from "../../assets/copy.svg";
import check from "../../assets/Check.svg";
import { GetCashBackByID } from "../../RequestHandlers/Voucher";
import { GetVouchersByID } from "../../RequestHandlers/Voucher";
import { formatDate, formatDate3 } from "../../Components/formatDate";
import { GetVoucherSaleChart } from "../../RequestHandlers/Voucher";
import Pagination from "../../Components/Pagination";
import Toast, { handleToast } from "../../Components/Toast";
import checkBox from "../../assets/Checkbox.svg";

function VoucherCashback() {
  const navigate = useNavigate();
  const [vLoad, setVLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [activeValue, setActiveValue] = useState("Last 14 days");
  const [activeDuration, setActiveDuration] = useState("last-14-days");
  const [chartData, setChartData] = useState<any>([]);
  const [cload, setCload] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const location = useLocation();
  const id = location.state.id || "";
  const cloadArr = [1, 2, 3, 4];
  const [details, setDetails] = useState<any>([]);
  const [meta, setMeta] = useState<any>([]);
  const [activeDetails, setActiveDetails] = useState<any>(null);
  const [searchword, setSearchword] = useState("")
  const totalPages = 10;

  const chartDurations = [
    { mDate: "Last 14 days", bDate: "last-14-days" },
    { mDate: "Last 30 days", bDate: "last-30-days" },
    { mDate: "Last 60 days", bDate: "last-60-days" },
    { mDate: "Last 90 days", bDate: "last-90-days" },
    { mDate: "Last 120 days", bDate: "last-120-days" },
  ];

  const [cashreqs, setCashReqs] = useState<any>([]);

  const getCashback = async () => {
    try {
      const res = await GetCashBackByID(page, limit, id);
      setCashReqs(res.items);
      setMeta(res.meta);
      console.log("casreq", res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getCashback();

    setChartData([
      {
        projectName: "Cityscape living",
        data: [
          { date: "Sep 7", amount: 1000 },
          { date: "Sep 11", amount: 500 },
          { date: "Sep 16", amount: 1500 },
        ],
      },
    ]);
  }, []);
  function calculateEndDate(
    startDateString: string,
    durationInDays: number
  ): string {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    // Parse the start date
    const startDate = new Date(startDateString);
    // Add the duration to the start date
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + durationInDays);
    // Format the end date
    const month = months[endDate.getMonth()];
    const day = endDate.getDate().toString().padStart(2, "0");
    const year = endDate.getFullYear();

    return `${month} ${day}, ${year} ` || "...";
  }
  const allAmountsAreZero = chartData.every((item: any) =>
    item.data.every((entry: any) => entry.amount === 0)
  );
  const getVoucher = async () => {
    try {
      const res = await GetVouchersByID(id);
      setDetails(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getVoucherChart = async () => {
    try {
      setCload(true);
      const res = await GetVoucherSaleChart(id, activeDuration);
      setChartData(res.projectCharts);
    } catch (error) {
      console.log(error);
    } finally {
      setCload(false);
    }
  };

  const handleNext = () => {
    if (page < meta.totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== page) setPage(pageNumber);
  };
  useEffect(() => {
    getVoucherChart();
  }, [activeDuration]);

  useEffect(() => {
    getVoucher();
    getVoucherChart();
  }, []);

  const copyToClipboard = (text: string) => {
    if (!text) return; // Guard clause for empty strings
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard:", text);
        handleToast("Copied to clipboard", "success");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
        handleToast("Failed to copy to clipboard", "error");
      });
  };

  return (
    <>
      {showDetails && (
        <div className="voucher-detail">
          <Toast />
          <div className="rep-issue req-req">
            <div className="del-promt-nav">
              <p>Cashback request</p>
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  setShowDetails(false);
                }}
              >
                <img src={mClose} alt="" />
              </button>
            </div>
            <div className="cash-vDetails">
              <div className="d-card-nav">
                <div className="d-card-wrap1">
                  <img
                    src={details.image}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <p>{details?.addedValue}% added value</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    className="pr-num"
                    style={{ backgroundColor: "#343434" }}
                  >
                    {details.code}
                  </div>
                  <p className="exp1">Expired</p>
                </div>
              </div>

              <div className="tran-details">
                <div className="tran-details-item">
                  <p>Voucher value:</p>
                  <p>${details.value?.toLocaleString()}</p>
                </div>
                <div className="tran-details-item">
                  <p>Added value:</p>
                  <p>{details?.addedValue}%</p>
                </div>
              </div>
              <div className="tran-details-item">
                <p>Account number:</p>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <p>{activeDetails.accountNumber}</p>
                  <button
                    style={{
                      all: "unset",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      copyToClipboard(activeDetails.accountNumber);
                    }}
                  >
                    <img src={copy} alt="" />
                  </button>
                </div>
              </div>
              <div className="tran-details-item">
                <p>Bank:</p>
                <p>{activeDetails.bankName}</p>
              </div>
              <div className="tran-details-item">
                <p>Account name:</p>
                <p>{activeDetails.accountName}</p>
              </div>
              <div className="tran-details-item">
                <p>Withdrawal type:</p>
                <p>Nigerian domicilary account</p>
              </div>
              <div className="tran-details-item">
                <p>SWIFT/BIC code:</p>
                <p>{activeDetails.swiftCode}</p>
              </div>
              <div className="tran-details-item">
                <p>Request made on: </p>
                <p>{formatDate(activeDetails?.created_at)}</p>
              </div>
              <div className="tran-details-item">
                <div style={{ display: "flex", alignItems: "center", gap: 18 }}>
                  <img
                    src={
                      activeDetails?.status !== "pending" &&
                      activeDetails?.status !== "failed"
                        ? check
                        : checkBox
                    }
                    alt=""
                  />
                  <p>We have made the payment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="dash-body">
        <SideNav activeTab="Vouchers" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div style={{ width: "100%" }}>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="back"
                style={{
                  all: "unset",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  cursor: "pointer",
                }}
              >
                <img src={chevronB} alt="" />
                Back
              </button>

              <div className="d-card-nav" style={{ marginTop: 36 }}>
                <div className="d-card-wrap1">
                  <img
                    src={details.image}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <p>{details?.addedValue}% added value</p>
                  <div className="pr-num">{details.code}</div>
                </div>
                <p className="exp1">Expired</p>
              </div>
            </div>
          </div>
          <p className="ovo">Voucher overview</p>
          <div className="dash-overview" style={{ marginTop: 16 }}>
            <div className="card-holder">
              <div className="holder1">
                <ValuesCard
                  extraClass={"item-126"}
                  delay={0.5}
                  text1="Date created"
                  icon={calender}
                  loading={vLoad}
                  bottomComp={
                    <>
                      <p className="count-value">
                        {formatDate3(details.created_at)}
                      </p>
                    </>
                  }
                />
                <ValuesCard
                  delay={1}
                  extraClass={"item-126"}
                  text1="Cashback requests"
                  loading={vLoad}
                  icon={cashReq}
                  bottomComp={
                    <>
                      <p className="count-value">{meta.totalItems}</p>
                    </>
                  }
                />
              </div>
              <div className="holder2">
                <ValuesCard
                  extraClass={"item-126"}
                  delay={0.5}
                  text1="Unit price"
                  icon={dolls}
                  loading={vLoad}
                  bottomComp={
                    <>
                      <p className="count-value">
                        ${details?.value?.toLocaleString() || "..."}
                      </p>
                    </>
                  }
                />
                <ValuesCard
                  delay={1}
                  extraClass={"item-126"}
                  text1="Past holders"
                  loading={vLoad}
                  icon={bTicket}
                  bottomComp={
                    <>
                      <p className="count-value">50</p>
                    </>
                  }
                />
              </div>
            </div>
            <div className="chart-holder">
              <div className="ch-header">
                <p>Impressions</p>
                <div
                  className="drop-holder"
                  style={{
                    width: 200,
                    // background: 'red',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    position: "relative",
                  }}
                >
                  <div
                    ref={dropdownRef}
                    className="drop-btn"
                    onClick={() => setIsOpen((prev) => !prev)}
                  >
                    {activeValue}{" "}
                    <img
                      className={`${isOpen ? "rotate" : ""}`}
                      src={dropdownicon}
                      alt=""
                    />
                  </div>
                  {isOpen && (
                    <div className="duration-drop">
                      {chartDurations.map((item, index) => {
                        return (
                          <div
                            className={`duration-text ${
                              activeDuration === item.bDate
                                ? "duration-active"
                                : ""
                            }`}
                            onClick={() => {
                              setActiveDuration(item.bDate);
                              setActiveValue(item.mDate);
                              setIsOpen(false);
                            }}
                          >
                            {item.mDate}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              {chartData.length === 0 ? (
                <></>
              ) : (
                <div className="ch-header2">
                  <p>{details?.viewCount?.toLocaleString()} views</p>
                </div>
              )}
              <div
                className="main-chart-cont"
                style={{
                  marginTop: chartData.length === 0 || cload ? 43 : 33,
                }}
              >
                {cload ? (
                  <>
                    {cloadArr.map((_: any, ind: number) => (
                      <div
                        className={`cload-dash ${
                          ind === cloadArr.length - 1 ? "" : "bottom-26"
                        }`}
                      ></div>
                    ))}
                  </>
                ) : chartData.length === 0 || allAmountsAreZero ? (
                  <>
                    <div className="empty-wrap" style={{ marginTop: 24 }}>
                      <img src={emptyChart} alt="" />
                      <p>No data Available</p>
                      <span>Your chart will be here</span>
                    </div>
                  </>
                ) : (
                  <LineChart backendData={chartData} backgroundDisabld={true} />
                )}
              </div>
            </div>
          </div>
          <div
            className="v-holder v-holder2"
            style={{ height: "auto", minHeight: 300 }}
          >
            <div className="v-head">
              <h4 className="v-head-text" style={{ fontSize: 18 }}>
                Cashback requests
              </h4>
            </div>
            <table className="activity-table">
              <thead>
                <tr>
                  <th className="th-first">Name</th>
                  <th>Amount invested</th>
                  <th>Amount spent</th>
                  <th>Balance requested</th>
                  <th className="th-action">Action</th>
                </tr>
              </thead>
              <tbody>
                {cashreqs.map((item: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td style={{ minWidth: 200 }}>
                        <div className="tr tr2">
                          {item?.user?.firstname} {item?.user?.lastname}
                        </div>
                      </td>
                      <td style={{ minWidth: 200 }}>
                        <div className="tr1 tr2">
                          ${item.userVoucher?.value?.toLocaleString()}
                        </div>
                      </td>
                      <td style={{ minWidth: 200 }}>
                        <div className="tr1 tr2">
                          ${item.userVoucher?.totalSpent?.toLocaleString()}
                        </div>
                      </td>
                      <td style={{ minWidth: 200 }}>
                        <div className="tr1 tr2">
                          ${item.userVoucher?.balance?.toLocaleString()}
                        </div>
                      </td>
                      <td>
                        <div className="tr-last1">
                          <button
                            onClick={() => {
                              console.log("jdcdnudufudju");

                              setShowDetails(true);
                              setActiveDetails(item);
                            }}
                            className="tr-btn"
                          >
                            View request
                          </button>
                          <button
                            // className="tr-btn"
                            onClick={() => {
                              setShowDetails(true);
                              setActiveDetails(item);
                            }}
                            style={{
                              position: "relative",
                              opacity: 0,
                              cursor: "pointer",
                            }}
                          >
                            View request
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {meta.totalPages > 1 && (
              <Pagination
                style={{ marginTop: 16 }}
                meta={meta}
                page={page}
                handleNext={handleNext}
                handlePageClick={handlePageClick}
                handlePrevious={handlePrevious}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VoucherCashback;
