import React, { useEffect, useState } from "react";
import SideNav from "../Components/SideNav";
import { useLocation, useNavigate } from "react-router-dom";
import chevronB from "../assets/ion_chevron-back.svg";
import search from "../assets/search.svg";
import c5 from "../assets/c5.png";
import retry from "../assets/retry.svg";
import { GetAllSpotlight } from "../RequestHandlers/Spotlight";
import { GetProjects } from "../RequestHandlers/Collection";


interface PaginatedItems<T> {
  items: T[];
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
    itemCount: number;
    totalItems: number;
  };
}

interface Item {
  name: string;
  industry: string;
  location: string;
  img: string;
}

function SearchScreen() {
  const navigate = useNavigate();
  const [searchWord, setSearchword] = useState("");
  const location = useLocation();
  const searchValue = location.state?.searchValue || "";
  const [searchData, setSearchData] = useState<any>([
    {
      img: c5,
      name: "Cityscape Living",
      industry: "Real estate",
      location: "Spotlight",
    },
    {
      img: c5,
      name: "Cityscape Living",
      industry: "Real estate",
      location: "Spotlight",
    },
    {
      img: c5,
      name: "Cityscape Living",
      industry: "Real estate",
      location: "Spotlight",
    },
  ]);
  const [data, setData] = useState<any>([]);
  const [spotlightData, setSpotlightData] = useState<PaginatedItems<Item>>({
    items: [],
    meta: {
      currentPage: 0,
      itemsPerPage: 0,
      totalPages: 0,
      itemCount: 0,
      totalItems: 0,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchValue) {
      setSearchword(searchValue);
    }
  }, []);

  const getProject = async () => {
    setLoading(true);
    try {
      const res = await GetProjects(1, 10, "all", searchWord);
      console.log(res);
      if (res.items) {
        setData(res.items);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Get spotlight data from the API
  const getAllSpotlight = async () => {
    setIsLoading(true);
    try {
      const res = await GetAllSpotlight();
      setSpotlightData(res); // Update spotlightData with the API response
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const mergedSearchData = [
    ...spotlightData.items.map((item: any) => ({
      id: item.id,
      title: item.title,
      resultType: "spotlight",
      type: item.type,
      location: item.owner?.location || item.location,
      cover: item.cover || c5, // Use a fallback image
    })),
    ...data.map((item: any) => ({
      id: item.id,
      title: item.name,
      resultType: "project",

      type: item.industry,
      location: item.officeLocation,
      cover: item.cover || c5,
    })),
  ];

  // Filter the merged data
   const filteredData = searchWord.trim() === "" ? [] : mergedSearchData.filter((item: any) => {
   return (
      item.title?.toLowerCase().includes(searchWord.toLowerCase()) ||
      item.type?.toLowerCase().includes(searchWord.toLowerCase()) ||
      item.location?.toLowerCase().includes(searchWord.toLowerCase())
    );
  });

  // Update search data after spotlight data and projects are fetched
  useEffect(() => {
    setSearchData(mergedSearchData); // Set merged data to searchData
  }, [spotlightData, data]); 


   useEffect(() => {
     const timer = setTimeout(() => {
        setIsLoading(true);
        getProject();
      getAllSpotlight();
    }, 100);
    return () => clearTimeout(timer); // Cleanup on every searchWord change
    }, [searchWord]); 
  return (
    <div className="dash-body">
      <SideNav activeTab="Dashboard" />
      <div className="content-body" style={{ paddingTop: 0 }}>
        <div
          className="back-nav"
          style={{
            width: "100%",
            height: 92,
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="back"
            style={{
              all: "unset",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 4,
              cursor: "pointer",
            }}
          >
            <img src={chevronB} alt="" />
            Back
          </button>
        </div>
        <div className="search-body">
          <div
            style={{
              width: 539,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className={`custom-input 
            showSBorder
            `}
            >
              <img src={search} alt="" />
              <input
                type="text"
                placeholder="Search"
                className="w-full"
                value={searchWord}
                onChange={(e) => {
                  setSearchword(e.target.value);
                }}
              />
            </div>
            <div
              className="search-it-header"
              style={{ marginTop: 46, paddingLeft: 10 }}
            >
              <p>
                {filteredData.length} results found for {searchWord}{" "}
              </p>
            </div>
            <>
              {searchWord && (isLoading || loading) ? (
                <div
                  className="loading-wrap text-white w-full"
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  <p>Searching...</p>
                 
                </div>
              ) : filteredData.length === 0 ? (
                <div
                  className="empty-wrap"
                  style={{ width: "100%", height: 200 }}
                >
                  <img src={retry} alt="" />
                  <p>Try again</p>
                  <span>No results found for your search.</span>
                </div>
              ) : (
                <>
                  {filteredData.map((item: any, index: number) => {
                    return (
                      <div
                        className="search-item search-item-border"
                        style={{
                          paddingBottom: 15,
                          marginTop: index === 0 ? 30 : 15,
                        }}
                        onClick={() => {
                          // Check if it's a project or spotlight and navigate accordingly
                          if (item.resultType === "project") {
                            // Navigate to the project details page
                            navigate(`/ProjectDetailsScreen`, {
                              state: {
                                data: { name: item.title, id: item.id },
                              },
                            });
                          } else {
                            // Navigate to the spotlight details page
                            navigate(`/SpotlightDetails`, {
                              state: { id: item.id },
                            });
                          }
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 12,
                          }}
                        >
                          <img
                            src={item.cover}
                            alt=""
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 100,
                            }}
                          />
                          <div>
                            <p
                              style={{
                                fontSize: "1rem",
                                color: "#fff",
                                fontWeight: 500,
                              }}
                            >
                              {item.title}
                            </p>
                            <p className="ssub-text capitalize">{item.type}</p>
                          </div>
                        </div>
                        <p className="ssub-text capitalize">{item.resultType}</p>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchScreen;
