import React from "react";
import envelop from "../../assets/envelop.gif";
import SecBtn from "../../Components/SecBtn";
import { useNavigate } from "react-router-dom";
import tick from "../../assets/tick.gif";

export default function RewardListed() {
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={tick} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Reward listed</h4>
        <p>
          Your reward listing is under review.
          <br />
          You’ll receive a confirmation once it's <br />
          processed.
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Back to rewards"
          onPress={() => {
            navigate("/Reward");
          }}
        />
      </div>
    </section>
  );
}
