import React from "react";
import envelop from "../assets/envelop.gif";
import SecBtn from "../Components/SecBtn";
import { useNavigate } from "react-router-dom";
import tick from "../assets/tick.gif";

export default function PasswordResetSuccess() {
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={tick} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Reset successful</h4>
        <p>
          Password reset successful. You can now
          <br />
          log in with your new password.
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Back to login"
          onPress={() => {
            navigate("/LoginScreen");
          }}
        />
      </div>
    </section>
  );
}
