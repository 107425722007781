import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CredentailsContext } from "../context/CredentailContext";
import closeIcon from "../assets/close.svg";

interface PProp {
  cancel?: any;
  logOut?: any;
}

let showLogoutFn: () => void;

export function handleLogout() {
  showLogoutFn();
}
function Logout() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { setStoredCredentails } = useContext(CredentailsContext);

  const handleLogout = () => {
    // Clear cookies
    Cookies.remove("webCook");

    // Clear context or any other state
    // @ts-ignore
    setStoredCredentails(null);
    navigate("/");
    setShow(false);
  };

  showLogoutFn = () => {
    setShow(true);
  };

  return show ? (
    <div className="logout-pop">
      <div className="logout-card">
        <div className="logout-header">
          <p>Logging out?</p>

          <img
            src={closeIcon}
            alt=""
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
        <div className="logout-content">
          <p className="logout-text">
            You can log back in anytime to continue where you left off.
          </p>
          <div className="logout-action-cont">
            <div
              className="logout-action-btn"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </div>
            <div
              className="logout-action-btn logout-btn"
              onClick={() => {
                handleLogout()
              }}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Logout;
