import React from "react";

interface PProp {
  loaderArr?: any;
}
export function TableLoader1({ loaderArr }: PProp) {
  return (
    <tbody className="tBody">
      {loaderArr.map((item: any) => (
        <tr key={item.id}>
          <td>
            <div className="tr">
              <div className="tr-text">
                <div className="t-load"></div>
              </div>
            </div>
          </td>
          <td>
            <div className="tr1">
              <div className={`f-item-load`}></div>
            </div>
          </td>
          <td>
            <div className="tr1">
              <div className="d-load"></div>
            </div>
          </td>
          <td className="funding-goal">
            <div className="tr1" style={{ paddingLeft: 10 }}>
              <div className="d-load"></div>
            </div>
          </td>
          <td>
            <div className="tr1" style={{ paddingLeft: 10 }}>
              <div className="prog-bar-cont" style={{ color: "#616161" }}>
                <div className="prog-bar">
                </div>
              </div>
            </div>
          </td>
          <td className="ddLine">
            <div className="tr-last">
              <div className="d-load"></div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
}
export function TableLoader2({ loaderArr }: PProp) {
  return (
    <tbody className="tBody">
      {loaderArr.map((item: any) => (
        <tr key={item.id}>
          <td>
            <div className="tr">
              <div className="img-load"></div>
              <div className="tr-text">
                <div className="t-load"></div>
                <div className="in-load"></div>
              </div>
            </div>
          </td>
          <td>
            <div className="tr1">
              <div className={`f-item-load`}></div>
            </div>
          </td>
          <td>
            <div className="tr1">
              <div className="d-load"></div>
            </div>
          </td>
          <td className="funding-goal">
            <div className="tr1" style={{ paddingLeft: 10 }}>
              <div className="d-load"></div>
            </div>
          </td>
          <td>
            <div className="tr1" style={{ paddingLeft: 10 }}>
              <div className="prog-bar-cont" style={{ color: "#616161" }}>
                <div className="prog-bar">
                  <div
                    className="bar"
                    style={{
                      width: "25%",
                      backgroundColor: "#838383",
                    }}
                  ></div>
                </div>
                <div className="prog-load"></div>
              </div>
            </div>
          </td>
          <td className="ddLine">
            <div className="tr-last">
              <div className="d-load"></div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
}

