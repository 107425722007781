import React, { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import closeIcon from "../../assets/close.svg";
import Input from "../../Components/Input";
import chevDown from "../../assets/chev-down.svg";
import { AddCollectionBlog } from "../../RequestHandlers/Collection";
import Toast, { handleToast } from "../../Components/Toast";

interface PProp {
  cancel?: any;
  logOut?: any;
  id: number;
}

let showLogoutFn: () => void;

export function handleAddUpdate() {
  showLogoutFn();
}

function AddUpdate({ id }: PProp) {
  const [show, setShow] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const [industries, setIndustries] = useState<any>(["News", "Milestones"]);
  const [dropActive, setDropActive] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [description, setDescription] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  showLogoutFn = () => {
    setShow(true);
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 195)}px`;
  };
  // Validation function

  const addBlog = async (body: object) => {
    setLoading(true);
    try {
      const res = await AddCollectionBlog(body);
      if (res.id) {
        // handleToast("Update added", "success");
        setBlogTitle("")
        setActiveIndustry(null)
        setDescription("")
        navigate("/UpdateAdded")
        setShow(false)
      } else {
        handleToast(res.meesage, "error");
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const validateInputs = () => {
    let isValid = true;
    const newErrors: any = {};
    if (!blogTitle.trim()) {
      newErrors.blogTitle = "Title is required.";
      isValid = false;
    }
    if (!activeIndustry) {
      newErrors.activeIndustry = "Please select an update type.";
      isValid = false;
    }
    if (!description.trim()) {
      newErrors.description = "Description is required.";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = () => {
    if (validateInputs()) {
      const body = {
        title: blogTitle,
        body: description,
        collectionId: id,
        updateType: activeIndustry,
      };
      addBlog(body);
      console.log("Form submitted with valid inputs");
    }
  };
  return show ? (
    <div className="logout-pop">
      <Toast />
      <div
        className="logout-card"
        style={{ minHeight: 526, height: "auto", paddingBottom: 24 }}
      >
        <div className="logout-header">
          <p>Add Update?</p>
          <img
            src={closeIcon}
            alt=""
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
        <div className="logout-content">
          <Input
            contStyle={{
              height: 64,
              backgroundColor: "#0D0D0D",
              marginTop: 24,
            }}
            label="Update Title"
            value={blogTitle}
            error={errors.blogTitle ? true : false}
            onChange={(e) => {
              setBlogTitle(e.target.value);
              setErrors((prevErrors: any) => ({
                ...prevErrors,
                blogTitle: "",
              }));
            }}
          />
          {errors.blogTitle && <p className="error-text">{errors.blogTitle}</p>}
          <div
            className="dropdown-holder"
            style={{ width: "100%", marginTop: 16 }}
          >
            <div
              className={`reg-file-cont drop-down ${
                activeIndustry !== null ? "focused" : ""
              } ${errors.activeIndustry ? "img-cont-error" : ""}`}
              onClick={() => {
                setDropActive(!dropActive);
              }}
            >
              <label className="floating-label">Update Type</label>
              <div className="active-ind">{activeIndustry}</div>
              <div className="chev">
                <img
                  src={chevDown}
                  alt=""
                  className={`${dropActive ? "rotate" : ""}`}
                />
              </div>
            </div>
            {errors.activeIndustry && (
              <p className="error-text">{errors.activeIndustry}</p>
            )}
            {dropActive && (
              <div
                className="in-drop-down"
                style={{ backgroundColor: "#262626" }}
              >
                {industries.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`ind ${
                        item === activeIndustry ? "ind-active" : ""
                      }`}
                      onClick={() => {
                        setActiveIndustry(item);
                        setDropActive(false);
                        setErrors((prevErrors: any) => ({
                          ...prevErrors,
                          activeIndustry: "",
                        })); // Clear error on select
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div
            className={`abt-talent-cont ${
              errors.description ? "img-cont-error" : ""
            }`}
            style={{ marginTop: 16, minHeight: 195 }}
          >
            <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
              Description
            </div>
            <textarea
              ref={textareaRef}
              className="abt-talent-text"
              value={description}
              style={{ height: 130 }}
              onChange={(e) => {
                setDescription(e.target.value);
                setErrors((prevErrors: any) => ({
                  ...prevErrors,
                  description: "",
                })); // Clear error on typing
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(description !== "" ? true : false)}
            />
          </div>
          {errors.description && (
            <p className="error-text">{errors.description}</p>
          )}

          <div className="logout-action-cont">
            <div
              className="logout-action-btn"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </div>
            <button
              className="logout-action-btn logout-btn"
              style={{
                backgroundColor: loading ? "#262626" : "#A7FC00",
                color: "#000",
                width: 75,
                fontWeight: 500,
                border: "none",
              }}
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? (
                <div className="dot-loader">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default AddUpdate;
