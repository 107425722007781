import React, { useState, useEffect, useContext } from "react";
import HeaderBar from "../Components/HeaderBar";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../Components/Input";
import FadeInAnimation from "../Components/FadeIn";
import SecBtn from "../Components/SecBtn";
import Radio from "../assets/Radio.svg";
import RadioCheck from "../assets/Radio-check.svg";
import { CreateUser, ResetPassword } from "../RequestHandlers/Auth";
import Cookies from "js-cookie";
import { CredentailsContext } from "../context/CredentailContext";
import Toast, { handleToast } from "../Components/Toast";

export default function CreatePassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [is8characters, setIs8Characters] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const { storedCredentails, setStoredCredentails } =
    useContext(CredentailsContext);
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const email = location.state?.email || {};

  const validatePassword = (value: string) => {
    setIs8Characters(value.length >= 8);
    setHasSymbol(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    setHasNumber(/[0-9]/.test(value));
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    validatePassword(value);
    if (value === "") {
      setError((prev) => ({ ...prev, password: "Password cannot be empty." }));
    } else {
      setError((prev) => ({ ...prev, password: "" }));
    }
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    if (value === "") {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Please confirm your password.",
      }));
    } else if (value !== password) {
      setPasswordsMatch(false);
      setError((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match.",
      }));
    } else {
      setPasswordsMatch(true);
      setError((prev) => ({ ...prev, confirmPassword: "" }));
    }
  };

  const createUser = async () => {
    setLoading(true);
    try {
      const body = {
        email: email.trim(),
        newPassword: password.trim(),
        confirmPassword: confirmPassword.trim(),
      };
      console.log(body);
      const res = await ResetPassword(body);
      console.log(res);
      if (res.status === true) {
        setLoading(false);
        navigate("/PasswordResetSuccess");
      } else {
        setLoading(false);
        handleToast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (is8characters && hasSymbol && hasNumber && passwordsMatch) {
      createUser();
    } else {
      alert("Please ensure all validation rules are met.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(email);
    
  }, []);

  return (
    <>
      <Toast />
      <div className="tk-reg-body">
        <HeaderBar
          onclick={() => {
            navigate(-1);
          }}
        />
        <div className="pageCont mTop40">
          <FadeInAnimation delay={0.3} duration={1}>
            <h4 className="pageH4">Reset your password</h4>
          </FadeInAnimation>
          <FadeInAnimation delay={0.6} duration={1}>
            <p className="pageP">
              Create a password to secure your account. Your password must have
              <br />
              at least 8 characters, 1 symbol, and 1 number.
            </p>
          </FadeInAnimation>
          <div className="inputCont1 mTop56">
            <FadeInAnimation delay={0.9} duration={1}>
              <Input
                isPassword={true}
                type="text"
                label="Type Password"
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
                error={error.password ? true : false}
                errorText={error.password}
              />
            </FadeInAnimation>
          </div>
          <div className="valid-password">
            <FadeInAnimation duration={1} delay={0.3}>
              <div className="vp-cont">
                <img
                  src={is8characters ? RadioCheck : Radio}
                  alt=""
                  className="radio"
                />
                8 characters
              </div>
            </FadeInAnimation>
            <FadeInAnimation delay={0.6} duration={1}>
              <div className="vp-cont">
                <img
                  src={hasSymbol ? RadioCheck : Radio}
                  alt=""
                  className="radio"
                />
                1 symbol
              </div>
            </FadeInAnimation>
            <FadeInAnimation duration={1} delay={0.9}>
              <div className="vp-cont">
                <img
                  src={hasNumber ? RadioCheck : Radio}
                  alt=""
                  className="radio"
                />
                1 number
              </div>
            </FadeInAnimation>
          </div>
          <div className="inputCont1 input-cont-vp">
            <FadeInAnimation delay={1.5} duration={1}>
              <Input
                isPassword={true}
                type="text"
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                error={error.confirmPassword ? true : false}
                errorText={error.confirmPassword}
              />
            </FadeInAnimation>
          </div>
          <div className="bottomCont">
            <FadeInAnimation delay={1.8} duration={1}>
              <SecBtn
                text="Reset password"
                onPress={handleSubmit}
                disabled={
                  !is8characters || !hasSymbol || !hasNumber || !passwordsMatch
                }
                loading={loading}
              />
            </FadeInAnimation>
          </div>
        </div>
      </div>
    </>
  );
}
