import React, { useEffect, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import plus from "../../assets/outline_plus.svg";
import search from "../../assets/search.svg";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import { ReactComponent as List } from "../../assets/list.svg";
import { ReactComponent as Grid } from "../../assets/grid.svg";
import FadeInTop from "../../Components/FadeInTop";
import c1 from "../../assets/c1.png";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import g1 from "../../assets/g1.png";
import g2 from "../../assets/g2.png";
import g3 from "../../assets/g3.png";
import g4 from "../../assets/g4.png";
import g5 from "../../assets/g5.png";
import FadeInAnimation from "../../Components/FadeIn";
import emptyList from "../../assets/empty-list.svg";
import { useNavigate } from "react-router-dom";
import { GetProjects } from "../../RequestHandlers/Collection";
import { formatDate } from "../../Components/formatDate";
import retry from "../../assets/retry.svg";
import useDebounce from "../../Components/DebounceSearch";

function ProjectScreen() {
  const [projects, setProjects] = useState<any>([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isListActive, setIsListActive] = useState(true);
  const [gridActive, setGridActive] = useState(false);
  const [sExpanded, setSExpanded] = useState(false);
  const [animatedWidth, setAnimatedWidth] = useState("0");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(0);
  const [status, setStatus] = useState("all");
  const [searchword, setSearchword] = useState("");
  const loaderArr = [1, 2, 3, 4, 5];
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const debouncedWord = useDebounce(searchword, 500);

  const getProject = async () => {
    setLoading(true);
    try {
      const res = await GetProjects(page, limit, status, debouncedWord);
      console.log(res);
      if (res.items) {
        setData(res.items);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onList = () => {
    setIsListActive(true);
    setGridActive(false);
  };
  const onGrid = () => {
    setGridActive(true);
    setIsListActive(false);
  };
  useEffect(() => {
    const expandTimeout = setTimeout(() => {
      setSExpanded(true);
    }, 500);

    // Return a cleanup function
    return () => clearTimeout(expandTimeout);
  }, []);

  useEffect(() => {
    getProject();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getProject();
  }, [limit, page]);
  useEffect(() => {
    if (debouncedWord !== undefined) {
      getProject();
    }
  }, [debouncedWord]);
  return (
    <div className="dash-body">
      <SideNav activeTab="Projects" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Projects</h4>
            <p>Take a tour of your listed projects</p>
          </div>
          <div
            className="lnp-btn"
            onClick={() => {
              navigate("/ListProjectPromtScreen");
            }}
          >
            <img src={plus} alt="" /> List new project
          </div>
        </div>
        {data.length > 0 || searchword ? (
          <div className={`mega-search ${sExpanded ? "sExpanded" : ""}`}>
            <FadeInTop delay={1} duration={1} className="search-nim">
              <div className="s-wrap">
                <img src={search} alt="" />
                <input
                  type="text"
                  value={searchword}
                  onChange={(e) => {
                    setSearchword(e.target.value);
                  }}
                  placeholder="Search project by name"
                />
              </div>
            </FadeInTop>

            <div className="actions-wrap">
              <FadeInTop delay={1.5} duration={1}>
                <div
                  className={`filter ${isFilterActive ? "action-active" : ""}`}
                >
                  <Filter style={{ stroke: "currentColor" }} />
                  Filter
                </div>
              </FadeInTop>
              <FadeInTop delay={2} duration={1}>
                <div
                  className={`action-icon ${
                    isListActive ? "action-active" : ""
                  } icon1`}
                  onClick={onList}
                >
                  <List style={{ stroke: "currentColor" }} />
                </div>
              </FadeInTop>
              <FadeInTop delay={2.5} duration={1}>
                <div
                  className={`action-icon ${gridActive ? "action-active" : ""}`}
                  onClick={onGrid}
                >
                  <Grid style={{ stroke: "currentColor" }} />
                </div>
              </FadeInTop>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          className={`pr-list-body ${
            data.length === 0 || loading ? "new-pr-height" : ""
          }`}
        >
          {loading ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Project</th>
                    <th>Status</th>
                    <th>Date Listed</th>
                    <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                    <th style={{ paddingLeft: 10 }}>Funding Progress</th>
                    <th>Funding Deadline</th>
                  </tr>
                </thead>
                <tbody className="tBody">
                  {loaderArr.map((item: any) => (
                    <tr key={item.id}>
                      <td>
                        <div className="tr">
                          <div className="img-load"></div>
                          <div className="tr-text">
                            <div className="t-load"></div>
                            <div className="in-load"></div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tr1">
                          <div className={`f-item-load`}></div>
                        </div>
                      </td>
                      <td>
                        <div className="tr1">
                          <div className="d-load"></div>
                        </div>
                      </td>
                      <td className="funding-goal">
                        <div className="tr1" style={{ paddingLeft: 10 }}>
                          <div className="d-load"></div>
                        </div>
                      </td>
                      <td>
                        <div className="tr1" style={{ paddingLeft: 10 }}>
                          <div
                            className="prog-bar-cont"
                            style={{ color: "#616161" }}
                          >
                            <div className="prog-bar">
                              <div
                                className="bar"
                                style={{
                                  width: "25%",
                                  backgroundColor: "#838383",
                                }}
                              ></div>
                            </div>
                            <div className="prog-load"></div>
                          </div>
                        </div>
                      </td>
                      <td className="ddLine">
                        <div className="tr-last">
                          <div className="d-load"></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              {data.length === 0 ? (
                <>
                  <div className="empty-wrap">
                    <img src={searchword ? retry : emptyList} alt="" />
                    <p>{searchword ? "Try again" : "No project available"} </p>
                    <span>
                      {searchword
                        ? "No results found for your search."
                        : "List of projects will appear here"}
                    </span>
                  </div>
                </>
              ) : (
                <div
                  className={`content ${
                    gridActive ? "grid-view" : "list-view"
                  }`}
                >
                  {gridActive ? (
                    // Render grid view
                    data.map((item: any) => {
                      return (
                        <div key={item.id} className="grid-card">
                          <FadeInAnimation>
                            <div className="wrap-sec1">
                              <img src={item.cover} alt="" />
                              <div
                                className="wrap-sec1-text"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div className="tr-text">
                                  <p>{item.name}</p>
                                  <span>{item.industry}</span>
                                </div>
                                <div
                                  className={`f-item ${
                                    item.status.includes("Failed")
                                      ? "f-item-error"
                                      : item.status.includes("progress")
                                      ? "f-item-prog"
                                      : item.status.includes("Draft")
                                      ? "f-item-draft"
                                      : ""
                                  }`}
                                  style={{ marginTop: 8 }}
                                >
                                  {item.status === "active"
                                    ? "Published"
                                    : item.status}
                                </div>
                              </div>
                            </div>
                            <div className="div" style={{ marginTop: 12 }}>
                              <div className="grid-t-list">
                                <p>Date Listed:</p>
                                <p>{formatDate(item.created_at)}</p>
                              </div>
                              <div className="grid-t-list mTop12">
                                <p>Funding Progress:</p>
                                <div
                                  className="prog-bar-cont"
                                  style={{ color: "#616161" }}
                                >
                                  <div className="prog-bar grid-prog-bar">
                                    <div
                                      className="bar"
                                      style={{
                                        // width: item.progress,
                                        width: `${
                                          item?.fundingProgress * 100
                                        }%`, // Bind to animated width state
                                        transition: "width 1.5s ease-in-out",
                                      }}
                                    ></div>
                                  </div>
                                  {item?.fundingProgress * 100}%
                                </div>
                              </div>
                              <div className="grid-t-list mTop12">
                                <p>Funding Goal:</p>
                                <p> ${item?.fundingGoal?.toLocaleString()}</p>
                              </div>
                            </div>
                          </FadeInAnimation>
                          <div className="view-d">View Details</div>
                        </div>
                      );
                    })
                  ) : (
                    // Render list (table) view
                    <FadeInAnimation delay={0.5} duration={1}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Project</th>
                            <th>Status</th>
                            <th>Date Listed</th>
                            <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                            <th style={{ paddingLeft: 10 }}>
                              Funding Progress
                            </th>
                            <th>Funding Deadline</th>
                          </tr>
                        </thead>
                        <tbody className="tBody">
                          {data.map((item: any) => (
                            <tr key={item.id}>
                              <td style={{ cursor: "pointer" }}>
                                <div
                                  className="tr"
                                  style={{ cursor: "ponter" }}
                                  onClick={() => {
                                    navigate("/ProjectDetailsScreen", {
                                      state: {
                                        data: { name: item.name, id: item.id },
                                      },
                                    });
                                  }}
                                >
                                  <img src={item.cover} alt="" />
                                  <div className="tr-text">
                                    <p>{item.name}</p>
                                    <span>{item.industry}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tr1">
                                  <div
                                    className={`f-item ${
                                      item.status.includes("Failed")
                                        ? "f-item-error"
                                        : item.status.includes("progress")
                                        ? "f-item-prog"
                                        : item.status.includes("Draft")
                                        ? "f-item-draft"
                                        : ""
                                    }`}
                                  >
                                   {item.status === "active"
                                    ? "Published"
                                    : item.status}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tr1">
                                  {" "}
                                  {formatDate(item.created_at)}
                                </div>
                              </td>
                              <td className="funding-goal">
                                <div
                                  className="tr1"
                                  style={{ paddingLeft: 10 }}
                                >
                                  ${item?.fundingGoal?.toLocaleString()}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="tr1"
                                  style={{ paddingLeft: 10 }}
                                >
                                  <div
                                    className="prog-bar-cont"
                                    style={{ color: "#616161" }}
                                  >
                                    <div className="prog-bar">
                                      <div
                                        className="bar"
                                        style={{
                                          width: `${
                                            item?.fundingProgress * 100
                                          }%`,
                                        }}
                                      ></div>
                                    </div>
                                    {item?.fundingProgress * 100}%
                                  </div>
                                </div>
                              </td>
                              <td className="ddLine">
                                <div className="tr-last">
                                  {formatDate(item.fundindDeadline)}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </FadeInAnimation>
                  )}
                </div>
              )}
            </>
          )}
          {/* Container that toggles between grid and list views */}
        </div>
      </div>
    </div>
  );
}

export default ProjectScreen;
