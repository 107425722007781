import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import chevBack from "../../assets/ion_chevron-back.svg";
import ProgressRing from "../../Components/ProgressRing";
import Input from "../../Components/Input";
import { useFileUpload } from "../../context/uploadFile";
import roundedCheck from "../../assets/rc.svg";
import ActivityIndicator from "../../Components/ActivityIndicator";
import uploadedImg from "../../assets/uploadedImg.svg";
import gallary from "../../assets/gallery.svg";
import trash from "../../assets/trash.svg";
import uploadImg from "../../assets/upload.svg";
import ImageVideoUploader from "../../Components/ImageVideoUploader";
import plus from "../../assets/plus.svg";
import Toast, { handleToast } from "../../Components/Toast";
import Cookies from "js-cookie";
import { removeMediaFromStorage } from "../../Components/RemoveImageFromDraft";
function CreateNewVoucher1() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [tName, setTName] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const [hangedFile, setHangedFile] = useState<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [aboutTalent, setAboutTalent] = useState("");
  const videoFileInputRef = useRef<HTMLInputElement[]>([]);
  const [image, setImages] = useState([
    { fileName: "", link: "", fileSize: "", completed: false },
  ]);
  const [videos, setVideos] = useState([
    { fileName: "", link: "", fileSize: "", completed: false },
  ]);
  const imgFileInputRef = useRef<HTMLInputElement[]>([]);
  const [isCompleted, setIsCompleted] = useState<boolean[]>([]);
  const [isUploading1, setIsUploading1] = useState<boolean[]>([]);
  const [progress, setProgress] = useState<number[]>([]);
  const [isVCompleted, setIsVCompleted] = useState<boolean[]>([]);
  const [isVUploading1, setIsVUploading1] = useState<boolean[]>([]);
  const [vProgress, setVProgress] = useState<number[]>([]);
  const [timer, setTimer] = useState<number>(0);
  const [draftData, setDraftData] = useState<any>([]);

  const updateStateAtIndex = (
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    index: number,
    value: any
  ) => {
    setState((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const saveToDraft = (credentials:any) => {
    try {
      const existingData = localStorage.getItem("vDraft");
      const parsedData = existingData ? JSON.parse(existingData) : {};
      const updatedData = {
        ...parsedData,
        ...credentials,
      };
      localStorage.setItem("vDraft", JSON.stringify(updatedData));
      // @ts-ignore
      handleToast("Saved to draft", "success");
      console.log(updatedData);
      console.log(credentials);
    } catch (err) {
      console.error("Error storing credentials:", err);
      handleToast("Error saving to draft", "error");
    }
  };
  
  const getDraftDetails = () => {
    try {
      const savedData = localStorage.getItem("vDraft");
      if (savedData) {
        const credentials = JSON.parse(savedData);
        setDraftData(credentials);
        console.log(credentials);
  
        if (credentials) {
          setTName(credentials.name);
          setAboutTalent(credentials.aboutTalent);
          setSelectedFile(credentials.img);
  
          if (credentials.images && credentials.images.length === 0) {
            setImages([
              { fileName: "", link: "", fileSize: "", completed: false },
            ]);
          } else {
            setImages(credentials.images || []);
          }
  
          if (credentials.videos && credentials.videos.length === 0) {
            setVideos([
              { fileName: "", link: "", fileSize: "", completed: false },
            ]);
          } else {
            setVideos(credentials.videos || []);
          }
  
          setUploadedFileUrl(credentials.img?.secure_url || "");
        }
  
        return credentials; // Return the parsed credentials
      } else {
        console.log("No draft found.");
        return null;
      }
    } catch (err) {
      console.error("Error retrieving draft details:", err);
      return null;
    }
  };
  
  // Initialize refs
  useEffect(() => {
    getDraftDetails();
    imgFileInputRef.current = image.map(
      (_, i) =>
        imgFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
    videoFileInputRef.current = image.map(
      (_, i) =>
        videoFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);

  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const handleUploadClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };
  const handleAddMoreImage = () => {
    setImages([
      ...image,
      { fileName: "", link: "", fileSize: "", completed: false },
    ]);
  };
  const handleAddMoreVideo = () => {
    setVideos([
      ...videos,
      { fileName: "", link: "", fileSize: "", completed: false },
    ]);
  };
  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          setSelectedFile(data);
          handleFieldChange("coverImage", e.target.value);
        } else {
          setSelectedFile(null);
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const handleImageSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      setImages((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...item,
                fileName: file.name,
                fileSize: `${fileSizeInKB} KB`,
              }
            : item
        )
      );
      updateStateAtIndex(setIsUploading1, index, true);
      let timer1 = 0;
      updateStateAtIndex(setProgress, index, 0);
      const intervalId = setInterval(() => {
        if (timer1 === 90) {
          clearInterval(intervalId);
        } else {
          timer1 += 10;
          updateStateAtIndex(setProgress, index, timer1);
        }
      }, 200);
      try {
        const data = await uploadFile(file);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          updateStateAtIndex(setProgress, index, 100);
          updateStateAtIndex(setIsCompleted, index, true);
          setImages((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    link: data.secure_url,
                    completed: true,
                  }
                : item
            )
          );
          handleFieldChange(`image${index}`, data.secure_url);
        } else {
          console.error("File upload failed: No secure_url received");
          setImages((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    fileName: "",
                    link: "",
                    fileSize: "",
                    completed: false,
                  }
                : item
            )
          );
        }
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        updateStateAtIndex(setIsUploading1, index, false);
      }
    }
  };

  const handleImageDelete = (index: number, fileName: string) => {
    updateStateAtIndex(setIsCompleted, index, false);
    setImages((prev: any) => {
      const updatedImages = prev.filter((_: any, idx: any) => idx !== index);
      updateStateAtIndex(setIsCompleted, index, false);
      removeMediaFromStorage("images", fileName, "vCook");
      if (updatedImages.length === 0) {
        return [
          {
            fileName: "",
            link: "",
            fileSize: "",
            completed: false,
          },
        ];
      }
      return updatedImages;
    });
  };

  const handleVideoSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      setVideos((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...item,
                fileName: file.name,
                fileSize: `${fileSizeInKB} KB`,
              }
            : item
        )
      );
      updateStateAtIndex(setIsVUploading1, index, true);
      let timer1 = 0;
      updateStateAtIndex(setVProgress, index, 0);
      const intervalId = setInterval(() => {
        if (timer1 === 90) {
          clearInterval(intervalId);
        } else {
          timer1 += 10;
          updateStateAtIndex(setVProgress, index, timer1);
        }
      }, 600);
      try {
        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          updateStateAtIndex(setVProgress, index, 100);
          updateStateAtIndex(setIsVCompleted, index, true);
          setVideos((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    link: data.secure_url,
                    completed: true,
                  }
                : item
            )
          );
          handleFieldChange(`video${index}`, data.secure_url);
        } else {
          console.error("File upload failed: No secure_url received");
          setVideos((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    fileName: "",
                    link: "",
                    fileSize: "",
                    completed: false,
                  }
                : item
            )
          );
        }
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        updateStateAtIndex(setIsVUploading1, index, false);
      }
    }
  };

  const handleVideoDelete = (index: number, fileName: any) => {
    updateStateAtIndex(setIsVCompleted, index, false);
    setVideos((prev: any) => {
      const updatedImages = prev.filter((_: any, idx: any) => idx !== index);
      updateStateAtIndex(setIsVCompleted, index, false);
      removeMediaFromStorage("videos", fileName, "vCook");
      if (updatedImages.length === 0) {
        return [
          {
            fileName: "",
            link: "",
            fileSize: "",
            completed: false,
          },
        ];
      }
      return updatedImages;
    });
  };

  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };

  const tryFileUploadAgain = async () => {
    try {
      const data = await uploadFile(hangedFile);
      setUploadedFileUrl(data.secure_url);
      if (data.secure_url) {
        setSelectedFile(data);
      } else {
        setSelectedFile(null);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const handleFormSubmit = (e: React.FormEvent, type: string) => {
    e.preventDefault();
    console.log("Form submission attempted");
    const newErrors: Record<string, string> = {};
    // Global field validations
    if (tName.trim() === "") {
      newErrors.tName = "Field is required";
    }
    if (uploadedFileUrl === null) {
      newErrors.coverImage = "Field is required";
    }
    if (aboutTalent.trim() === "") {
      newErrors.aboutTalent = "Field is required";
    }
    const hasUploadedFile =
      image.some((item) => item.link) || videos.some((item) => item.link); // Check if any file has a link in either state
    if (!hasUploadedFile) {
      newErrors.uploadedFiles =
        "At least one image or video needs to be uploaded";
    }
    handleToast("Upload at least 1 image or video", "error");
    // If errors exist, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, proceed with form submission
    console.log("Form submitted successfully:", {
      tName,
      uploadedFileUrl,
      aboutTalent,
      image,
      videos,
    });

    // Clear errors and navigate
    if (type === "draft") {
      const data = {
        name: tName,
        img: selectedFile,
        aboutTalent: aboutTalent,
        images: image,
        videos: videos,
      };
      saveToDraft(data);
    } else {
      navigate("/CreateNewVoucher2", {
        state: {
          data: {
            name: tName,
            img: uploadedFileUrl,
            aboutTalent: aboutTalent,
            images: image,
            videos: videos,
          },
        },
      });
    }
  };
  return (
    <div className="dash-body">
      <Toast />
      <SideNav activeTab="Vouchers" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>Create New Voucher</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={33.3}
            text={"1/3"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <Input
              contClass="list-input-cont"
              extraClass="list-input"
              label="Business Name"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              value={tName}
              onChange={(e) => {
                setTName(e.target.value);
                handleFieldChange("tName", e.target.value);
              }}
              error={errors.tName}
              errorText={errors.tName}
            />
            <div
              className={`img-upload-cont ${
                selectedFile ? "img-upload-cont1" : ""
              } ${errors.coverImage ? "img-cont-error" : ""}`}
              onClick={() => {
                handleUploadClick();
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              {selectedFile || isUploading ? (
                <div className="upp-nav">
                  {selectedFile ? <p>Cover Image</p> : <div></div>}
                  {isUploading ? (
                    <ActivityIndicator size="24px" color="#a7fc00" />
                  ) : (
                    <img src={roundedCheck} />
                  )}
                </div>
              ) : (
                <></>
              )}
              <img src={selectedFile ? uploadedImg : uploadImg} alt="" />
              <p>
                {" "}
                {selectedFile
                  ? "Image Uploaded Successfully"
                  : "Upload Business Related Image "}
              </p>
              <span
                onClick={() => {
                  if (uploadError) {
                    tryFileUploadAgain();
                  }
                }}
              >
                {selectedFile
                  ? `${selectedFile?.original_filename}.${selectedFile?.format}`
                  : uploadError
                  ? "Try again"
                  : "(375*150 recommended)"}
              </span>
              {selectedFile && (
                <div className="bbb-nav">
                  <div className="nn-wrap">
                    <img src={gallary} alt="" />
                    <p>
                      {selectedFile?.original_filename}.{selectedFile?.format}
                    </p>
                  </div>
                  <img
                    src={trash}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedFile(null);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={`abt-talent-cont ${
                errors.aboutTalent ? "img-cont-error" : ""
              }`}
            >
              <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                About Company
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={aboutTalent}
                onChange={(e) => {
                  setAboutTalent(e.target.value);
                  adjustHeight(e.target);
                  handleFieldChange("aboutTalent", e.target.value);
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => setIsFocused(aboutTalent != "" ? true : false)}
              />
            </div>
            {errors.aboutTalent && (
              <p className="error-text">{errors.aboutTalent}</p>
            )}
          </div>
          <div className="content-cont">
            <p className="ov">Gallery</p>
            <div className="gallary-wrap">
              <div className="gal-hold">
                {image.map((item, index) => {
                  return (
                    <ImageVideoUploader
                      topText="Upload Image"
                      isUploading={isUploading1[index] || false}
                      isCompleted={item.completed}
                      progress={progress[index]}
                      fileInputRef={(el: any) =>
                        (imgFileInputRef.current[index] = el)
                      }
                      accept="image/*"
                      handleFileSelect={(e: any) => {
                        handleImageSelect(index, e);
                      }}
                      del={(e: any) => {
                        e.stopPropagation();
                        handleImageDelete(index, item.fileName);
                      }}
                      type="image"
                      fileName={item.fileName}
                      fileSize={item.fileSize}
                      onContainerClick={() => {
                        imgFileInputRef.current[index].click();
                      }}
                    />
                  );
                })}
                <div className="inc-btn-cont">
                  <div
                    className="add-btn"
                    onClick={() => {
                      handleAddMoreImage();
                    }}
                  >
                    <img src={plus} alt="" /> Add Image
                  </div>
                </div>
              </div>
              <div className="gal-hold">
                {videos.map((item, index) => {
                  return (
                    <ImageVideoUploader
                      topText="Upload Video"
                      isUploading={isVUploading1[index] || false}
                      isCompleted={item.completed}
                      progress={vProgress[index]}
                      fileInputRef={(el: any) =>
                        (videoFileInputRef.current[index] = el)
                      }
                      type="video"
                      accept="video/*"
                      handleFileSelect={(e: any) => {
                        handleVideoSelect(index, e);
                      }}
                      del={(e: any) => {
                        e.stopPropagation();
                        handleVideoDelete(index, item.fileName);
                      }}
                      fileName={item.fileName}
                      fileSize={item.fileSize}
                      onContainerClick={() => {
                        videoFileInputRef.current[index].click();
                      }}
                    />
                  );
                })}
                <div className="inc-btn-cont">
                  <div
                    className="add-btn"
                    onClick={() => {
                      handleAddMoreVideo();
                      console.log(videos);
                    }}
                  >
                    <img src={plus} alt="" /> Add Video
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="std-cont">
            <div
              className="std-btn"
              onClick={(e) => {
                handleFormSubmit(e, "draft");
              }}
            >
              Save to draft
            </div>
            <div
              className="c-btn"
              onClick={(e) => {
                handleFormSubmit(e, "submit");
              }}
            >
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewVoucher1;
