import "../../css/profile/styles.css";
import Input from "../Input";
import { useState } from "react";
import uploadImage from "../../assets/file-upload.svg";
import Radio from "../../assets/Radio.svg";
import RadioCheck from "../../assets/Radio-check.svg";
import FadeInAnimation from "../FadeIn";
import SecBtn from "../SecBtn";
import { UpdateUserPassword } from "../../RequestHandlers/User";
import Toast, { handleToast } from "../Toast";

const ProfilePassword = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [is8Characters, setIs8Characters] = useState(false);
    const [hasSymbol, setHasSymbol] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [updating, setUpdating] = useState(false)

    const [errors, setErrors] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const validatePassword = (value: string) => {
        setIs8Characters(value.length >= 8);
        setHasSymbol(/[!@#$%^&*(),.?":{}|<>]/.test(value));
        setHasNumber(/[0-9]/.test(value));
    };

    const handleCurrentPasswordChange = (value: string) => {
        setCurrentPassword(value);
        setErrors((prev) => ({
            ...prev,
            currentPassword: value ? "" : "Current password is required.",
        }));
    };

    const handleNewPasswordChange = (value: string) => {
        setNewPassword(value);
        validatePassword(value);
        setErrors((prev) => ({
            ...prev,
            newPassword: value
                ? ""
                : "New password cannot be empty.",
        }));
    };

    const handleConfirmPasswordChange = (value: string) => {
        setConfirmPassword(value);
        if (value !== newPassword) {
            setPasswordsMatch(false);
            setErrors((prev) => ({
                ...prev,
                confirmPassword: "Passwords do not match.",
            }));
        } else {
            setPasswordsMatch(true);
            setErrors((prev) => ({
                ...prev,
                confirmPassword: "",
            }));
        }
    };

    const handleSubmit = async () => {
        if (
            currentPassword &&
            is8Characters &&
            hasSymbol &&
            hasNumber &&
            passwordsMatch
        ) {
            setUpdating(true)
            try {
                const response = await UpdateUserPassword({
                    newPassword,
                    confirmPassword
                })

                if (response) {
                    handleToast("Password changed", "success")
                }
            } catch (err: any) {
                handleToast(err.message || "Failed to update password. Please try again.", "error")
            }
            finally {
                setUpdating(false)
            }
        } else {
            handleToast("Please ensure all validation rules are met.", "error");
        }
    };


    // const isFormValid =
    // currentPassword &&
    // is8Characters &&
    // hasSymbol &&
    // hasNumber &&
    // passwordsMatch;

    const isFormValid = !currentPassword ||
        !is8Characters ||
        !hasSymbol ||
        !hasNumber ||
        !passwordsMatch

    return (
        <div className="profile-password-view" >

            <div className="profile-form-container">
                <div style={{ width: "100%" }}>
                    <h2 className="profile-form-title">Update Password</h2>
                </div>

                <form className="profile-form" onSubmit={(e) => e.preventDefault()}>
                    <Input
                        isPassword={true}
                        type="password"
                        value={currentPassword}
                        onChange={(e) => handleCurrentPasswordChange(e.target.value)}
                        error={!!errors.currentPassword}
                        errorText={errors.currentPassword}
                        contStyle={{ margin: "16px 0px", background: "#0D0D0D" }}

                        label="Current password"
                    />

                    <Input
                        isPassword={true}
                        type="password"
                        value={newPassword}
                        onChange={(e) => handleNewPasswordChange(e.target.value)}
                        error={!!errors.newPassword}
                        errorText={errors.newPassword}
                        contStyle={{ margin: "16px 0px", background: "#0D0D0D" }}
                        label="New password"
                    />
                    <div className="valid-password">
                        {/* <FadeInAnimation duration={1} delay={0.3}> */}
                        <div className="vp-cont">
                            <img
                                src={is8Characters ? RadioCheck : Radio}
                                alt={is8Characters ? "Valid" : "Invalid"}
                                className="radio"
                            />
                            8 characters
                        </div>
                        {/* </FadeInAnimation> */}
                        {/* <FadeInAnimation delay={0.6} duration={1}> */}
                        <div className="vp-cont">
                            <img
                                src={hasSymbol ? RadioCheck : Radio}
                                alt={hasSymbol ? "Valid" : "Invalid"}
                                className="radio"
                            />
                            1 symbol
                        </div>
                        {/* </FadeInAnimation> */}
                        {/* <FadeInAnimation duration={1} delay={0.9}> */}
                        <div className="vp-cont">
                            <img
                                src={hasNumber ? RadioCheck : Radio}
                                alt={hasNumber ? "Valid" : "Invalid"}
                                className="radio"
                            />
                            1 number
                        </div>
                        {/* </FadeInAnimation> */}
                    </div>

                    <Input
                        isPassword={true}
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                        error={!!errors.confirmPassword}
                        errorText={errors.confirmPassword}
                        contStyle={{ margin: "16px 0px", background: "#0D0D0D" }}
                        label="Confirm password"
                    />
                </form>

                <div className="save-view">
                    <SecBtn
                        text="Cancel"
                        style={{
                            background: "transparent",
                            color: "#FFFFFF",
                            width: "72px",
                            padding: " 8px 14px",
                            borderRadius: "12px",
                            border: "1px solid #4A4A4A",
                            fontSize: "14px",
                            fontWeight: "500",
                            outline: "none",
                            height: 42
                        }}
                        onPress={() => {
                            setCurrentPassword("");
                            setNewPassword("");
                            setConfirmPassword("");
                            setIs8Characters(false);
                            setHasSymbol(false);
                            setHasNumber(false);
                            setPasswordsMatch(false);
                            setErrors({
                                currentPassword: "",
                                newPassword: "",
                                confirmPassword: "",
                            });
                        }}
                    />


                    <SecBtn
                        loading={updating}
                        style={{
                            color: " #030500",
                            width: "139px",
                            padding: "8px 0px",

                            borderRadius: " 12px",
                            fontSize: "14px",
                            fontWeight: "500",
                            backgroundColor: "#A7FC00",
                            opacity: isFormValid ? 0.6 : 1,
                            height: 42
                        }}
                        onPress={handleSubmit}
                        disabled={
                            isFormValid
                        }
                        text={"Update Password"}
                    />
                </div>
            </div>
            <Toast />
        </div>
    );
};

export default ProfilePassword;
