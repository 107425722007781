import React, { useState, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import chevronB from "../../assets/ion_chevron-back.svg";
import FadeInTop from "../../Components/FadeInTop";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import search from "../../assets/search.svg";
import verse from "../../assets/verse.png";
import vImg from "../../assets/vImg.png";
import emptyList from "../../assets/empty-list.svg";
import VHistory from "../../Components/VHistory";
import { formatDate } from "../../Components/formatDate";
import { GetVoucherHolder } from "../../RequestHandlers/Voucher";
import retry from "../../assets/retry.svg";
import Pagination from "../../Components/Pagination";
import useDebounce from "../../Components/DebounceSearch";

function VoucherHolders() {
  const navigate = useNavigate();
  const [searchword, setSearchword] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [sExpanded, setSExpanded] = useState(false);
  const [showvHistory, setShowVHistory] = useState(false);
  const [vHload, setVHLoad] = useState(false);
  const hLoadArr = [1, 2, 3, 4, 5, 6];
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [meta, setMeta] = useState<any>([]);
  const [holderDetails, setHolderDetails] = useState<any>([]);
  const [id, setID] = useState("");
  const [data, setData] = useState<any>([]);
  const debouncedWord = useDebounce(searchword, 500)
  const getVoucherHolders = async () => {
    setVHLoad(true);
    try {
      const res = await GetVoucherHolder(page, limit, debouncedWord);
      setData(res.items);
      setMeta(res.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setVHLoad(false);
    }
  };

  useEffect(() => {
    getVoucherHolders();
  }, [debouncedWord, page]);

  const handleNext = () => {
    if (page < meta.totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== page) setPage(pageNumber);
  };

  useEffect(() => {
    getVoucherHolders();
    const expandTimeout = setTimeout(() => {
      setSExpanded(true);
    }, 500);

    // Return a cleanup function
    return () => clearTimeout(expandTimeout);
  }, []);

  return (
    <>
      {showvHistory && (
        <div className="voucher-detail">
          <VHistory
            onClose={() => setShowVHistory(false)}
            id={id}
            holderDetails={holderDetails}
          />
        </div>
      )}
      <div className="dash-body">
        <SideNav activeTab="Vouchers" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div style={{ width: "100%" }}>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="back"
                style={{
                  all: "unset",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  cursor: "pointer",
                }}
              >
                <img src={chevronB} alt="" />
                Back
              </button>
              <p className="ovo ovh">Voucher holders</p>
            </div>
          </div>
          {data.length > 0 || searchword ? (
            <div className={`mega-search ${sExpanded ? "sExpanded" : ""}`}>
              <FadeInTop delay={1} duration={1} className="search-nim">
                <div className="s-wrap">
                  <img src={search} alt="" />
                  <input
                    type="text"
                    value={searchword}
                    onChange={(e) => {
                      setSearchword(e.target.value);
                    }}
                    placeholder="Search voucher by name"
                  />
                </div>
              </FadeInTop>
              <div className="actions-wrap">
                <FadeInTop delay={1.5} duration={1}>
                  <div
                    className={`filter ${
                      isFilterActive ? "action-active" : ""
                    }`}
                    style={{ margin: 0 }}
                  >
                    <Filter style={{ stroke: "currentColor" }} />
                    Filter
                  </div>
                </FadeInTop>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="v-holder new-hold-height">
            {vHload ? (
              <>
                <div className="v-cards-wrap">
                  {hLoadArr.map(() => (
                    <div
                      className="voucher-card-load"
                      style={{ marginTop: 16 }}
                    ></div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {data.length === 0 ? (
                  <div className="empty-wrap" style={{ height: 500 }}>
                    <img src={searchword ? retry : emptyList} alt="" />
                    <p>{searchword ? "Try again" : "No project available"} </p>
                    <span>
                      {searchword
                        ? "No results found for your search."
                        : "List of projects will appear here"}
                    </span>
                  </div>
                ) : (
                  <div className="v-cards-wrap">
                    {data.map((item: any, index: number) => {
                      return (
                        <div
                          className="voucher-card"
                          style={{ cursor: "pointer", marginBottom: 16 }}
                          onClick={() => {
                            setShowVHistory(true);
                            setID(item.id);
                            setHolderDetails(item);
                          }}
                        >
                          <img src={item.brandVoucher.image} alt="" />
                          <div className="v-card-b">
                            <div className="ball b-left"></div>
                            <div className="ball b-right"></div>
                            <h4 className="h-name">
                              {item?.user?.firstname} {item?.user?.lastname}
                            </h4>
                            <div className="v-details-wrap">
                              <div className="v-details-item">
                                <h4>${item.value?.toLocaleString()}</h4>
                                <p>{item.code}</p>
                              </div>
                              <div className="v-details-item">
                                <h5>
                                  ${item?.totalSpent?.toLocaleString()} value
                                  spent
                                </h5>
                                <p className="exp">
                                  Expires {formatDate(item.expiry)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {meta.totalPages > 1 && (
              <Pagination
                style={{ marginTop: 16 }}
                meta={meta}
                page={page}
                handleNext={handleNext}
                handlePageClick={handlePageClick}
                handlePrevious={handlePrevious}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VoucherHolders;
