import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../../Components/SideNav";
import ProfileNav from "../../../Components/ProfileNav";
import chevBack from "../../../assets/ion_chevron-back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressRing from "../../../Components/ProgressRing";
import Input from "../../../Components/Input";
import uploadImg from "../../../assets/upload.svg";
import chevDown from "../../../assets/chev-down.svg";
import { GetIndustries } from "../../../RequestHandlers/Auth";
import colon from "../../../assets/colon.svg";
import NumberMonthDropdown from "../../../Components/NumberMonthDropdown";
import plus from "../../../assets/plus.svg";
import { useFileUpload } from "../../../context/uploadFile";
import roundedCheck from "../../../assets/rc.svg";
import uploadedImg from "../../../assets/uploadedImg.svg";
import gallary from "../../../assets/gallery.svg";
import trash from "../../../assets/trash.svg";
import ActivityIndicator from "../../../Components/ActivityIndicator";
import { GetUserDetails } from "../../../RequestHandlers/User";
import { CreateProject } from "../../../RequestHandlers/Collection";
import Toast, { handleToast } from "../../../Components/Toast";

interface Row {
  months: string;
  roi: string;
}
interface fwork {
  title: string;
  document: string;
  link: string;
  fileName?: string;
}
interface Partnerships {
  company: string;
  duration: string;
  purpose: string;
}
interface stakeHolders {
  name: string;
  role: string;
  url: string;
}
function CompanyDetails2() {
  const navigate = useNavigate();
  const location = useLocation();
  const allData = location?.state.allData || {};
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [webUrl, setWebUrl] = useState("");
  const [LinkedInUrl, setLinkedInUrl] = useState("");
  const [email, setEmail] = useState("");
  const [cnum, setCnum] = useState("");
  const [isFocused, setIsFocused] = useState("");
  const [skills, setSkills] = useState<string[]>(["", "", ""]);
  const [errors, setErrors] = useState<any>({});
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const urlRegex = /^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/.*)?$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const cNumRegex =
    /^\+?(\d{1,3})?[-. (]*(\d{1,4})[-. )]*(\d{1,4})[-. ]*(\d{1,9})$/;
  const [ownerId, setOwnerId] = useState(0);
  const [compName, setcompName] = useState("");
  const [loading, setLoading] = useState(false);

  const [featuredWorks, setFeaturedWorks] = useState<fwork[]>([
    { title: "", document: "", link: "", fileName: "" },
  ]);
  const [supportingDoc, setSupportingDoc] = useState<fwork[]>([
    { title: "", document: "", link: "", fileName: "" },
  ]);
  const [partnerships, setPartnerships] = useState<Partnerships[]>([
    { company: "", duration: "", purpose: "" },
  ]);
  const [stakeHolder, setStakeHolders] = useState<stakeHolders[]>([
    { name: "", role: "", url: "" },
  ]);
  const handleAddMore = () => {
    setFeaturedWorks([
      ...featuredWorks,
      { title: "", document: "", link: "", fileName: "" },
    ]);
  };
  const handleAddMorePartnerships = () => {
    setPartnerships([
      ...partnerships,
      { company: "", duration: "", purpose: "" },
    ]);
  };
  const handleAddMoreSp = () => {
    setSupportingDoc([
      ...supportingDoc,
      { title: "", document: "", link: "", fileName: "" },
    ]);
  };
  const handleAddSh = () => {
    setStakeHolders([...stakeHolder, { name: "", role: "", url: "" }]);
  };

  const handlePChange = (
    index: number,
    field: keyof Partnerships,
    value: string
  ) => {
    const updateField = [...partnerships];
    updateField[index][field] = value;
    setPartnerships(updateField);
  };
  const handleSpChange = (index: number, field: keyof fwork, value: string) => {
    const updateField = [...supportingDoc];
    updateField[index][field] = value;
    setSupportingDoc(updateField);
  };
  const handleSHChange = (
    index: number,
    field: keyof stakeHolders,
    value: string
  ) => {
    const updateField = [...stakeHolder];
    updateField[index][field] = value;
    setStakeHolders(updateField);
  };
  const handleInputChange1 = (
    index: number,
    field: keyof fwork,
    value: string
  ) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              [field]: value,
              ...(field === "link" ? { document: "" } : {}),
              ...(field === "document" ? { link: "" } : {}),
            }
          : item
      )
    );
  };
  const handleInputChangeDoc = (
    index: number,
    field: keyof fwork,
    value: string
  ) => {
    setSupportingDoc((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              [field]: value,
              ...(field === "link" ? { document: "" } : {}),
              ...(field === "document" ? { link: "" } : {}),
            }
          : item
      )
    );
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const handleFileSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);
        const data = await uploadFile(file); // Replace with your actual upload logic
        console.log("Uploaded file:", data);

        if (data.secure_url) {
          handleInputChange1(index, "document", data.secure_url);
          setFeaturedWorks((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    document: data.secure_url,
                    fileName: file.name, // Add the file name
                    link: "", // Clear the link field
                  }
                : item
            )
          );
          handleFieldChange(`dd${index}`, data.secure_url);
          handleFieldChange(`ll${index}`, data.secure_url);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const handleFileSelect1 = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file); // Replace with your actual upload logic
        console.log("Uploaded file:", data);

        if (data.secure_url) {
          handleInputChangeDoc(index, "document", data.secure_url);
          setSupportingDoc((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    document: data.secure_url,
                    fileName: file.name, // Add the file name
                    link: "", // Clear the link field
                  }
                : item
            )
          );
          handleFieldChange(`dds${index}`, data.secure_url);
          handleFieldChange(`lls${index}`, data.secure_url);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const fileInputRefs = useRef<HTMLInputElement[]>([]);
  const fileInputRefs1 = useRef<HTMLInputElement[]>([]);

  // Initialize refs
  useEffect(() => {
    fileInputRefs.current = featuredWorks.map(
      (_, i) => fileInputRefs.current[i] || React.createRef<HTMLInputElement>()
    );
    fileInputRefs1.current = supportingDoc.map(
      (_, i) => fileInputRefs.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };

  const removeUploadedFile = (index: number) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, document: "", fileName: "" } : item
      )
    );
  };
  const removeUploadedDoc = (index: number) => {
    setSupportingDoc((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, document: "", fileName: "" } : item
      )
    );
  };
  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.id) {
        setOwnerId(res.id);
        setcompName(res.companyname);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const createProject = async (object: object) => {
    setLoading(true);
    try {
      const res = await CreateProject(object);
      console.log(res);
      if (res.collection) {
        navigate("/ProjectListed");
      } else {
        handleToast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    console.log("submit", newErrors);

    if (webUrl.trim() === "") {
      newErrors.webUrl = "Field is required";
    } else if (!urlRegex.test(webUrl)) {
      newErrors.webUrl = "Invalid URL format";
    }
    if (LinkedInUrl.trim() === "") {
      newErrors.LinkedInUrl = "Field is required";
    } else if (!urlRegex.test(LinkedInUrl)) {
      newErrors.LinkedInUrl = "Invalid URL format";
    }
    if (email.trim() === "") {
      newErrors.email = "Field is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email address";
    }
    stakeHolder.slice(0, 1).forEach((item, index) => {
      if (item.name === "") {
        newErrors[`n${index}`] = "Field is required";
      }
      if (item.role === "") {
        newErrors[`r${index}`] = "Field is required";
      }
      if (item.url === "") {
        newErrors[`url${index}`] = "Field is required";
      } else if (!urlRegex.test(item.url)) {
        newErrors[`url${index}`] = "Invalid URL format";
      }
    });
    supportingDoc.slice(0, 1).forEach((item, index) => {
      if (item.title === "") {
        newErrors[`tts${index}`] = "Field is required";
      }
      if (item.document === "" && item.link === "") {
        newErrors[`dds${index}`] = "Field is required";
        newErrors[`lls${index}`] = "Field is required";
      } else if (item.document !== "" && item.link !== "") {
        newErrors[`dds${index}`] = "Field is required";
        newErrors[`lls${index}`] = "Field is required";
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const supportingDocuments = allData.data2.supportingDoc.map((item: any) =>
      item.document.trim() !== "" ? item.document : item.link
    );
    const portfolio = allData.data3.portfolio.map((item: any) => ({
      name: item.title,
      link: item.link.trim() !== "" ? item.link : item.document,
    }));
    const archievements = featuredWorks.map((item: any) => ({
      name: item.title,
      link: item.link.trim() !== "" ? item.link : item.document,
    }));
    const endorsement = allData.data2?.endorsement.map((item: any) => ({
      name: item.title,
      link: item.link.trim() !== "" ? item.link : item.document,
    }));
    const mainfeaturedWorks = allData.data.featuredWorks.map((item: any) => ({
      name: item.title,
      link: item.link.trim() !== "" ? item.link : item.document,
    }));
    const stakeHolders = stakeHolder.map((item: any) => ({
      name: item.name,
      role: item.role,
      link: item.url,
    }));
    const ROI = allData.data.rows.map((item: any) => ({
      name: `${allData.data.name}${item.months}`,
      months: Number(item.months),
      durationInDays: Number(item.months) * 30,
      percentage: Number(item.roi),
    }));
    const body = {
      name: allData.data.name,
      description: allData.data.aboutTalent,
      category: allData.data.activeIndustry.name,
      tokenType: "FixedIncomeTokens",
      status: "active",
      cover: allData.data.img,
      ownerId: ownerId,
      // incomePlan: "string",
      nftPrice: Number(allData.data.unit),
      nftMintLimit: Number(allData.data.aUnit),
      platform: "trendx",
      platformCollectionType: "talent",
      industry: allData.data.activeIndustry.name,
      fundingGoal: Number(allData.data.fg),
      fundingRound: allData.data.fundingRound,
      officeLocation: allData.data3.location,
      currency: "usd",
      featured: true,
      nftImage: allData.data.img,
      baseNftName: allData.data.name,
      blockchainNetwork: "polygon",
      useOfFunds: allData.data.use,
      supportingDocuments: supportingDocuments,
      riskFactors: allData.data2.riskfactor,
      mitigationStrategies: allData.data2.mts,
      companyDescription: allData.data3.abtComp,
      companyName: compName,
      companyLinkedin: allData.data3.ceoLinkdin,
      mission: allData.data3.mission,
      vision: allData.data3.vision,
      strategies: allData.data3.strategy,
      // competitors: allData.data2.competitors.join(", "),
      // competitiveAdvantages: allData.data2.advantages.join(", "),
      portfolio: portfolio,
      achievements: archievements,
      partnerships: partnerships,
      stakeholders: stakeHolders,
      featuredWorks: mainfeaturedWorks,
      endorsements: endorsement,
      returnOfInvestmentDurations: ROI,
    };
    console.log("submited", {
      body,
    });
    setErrors({});
    createProject(body);

    // navigate("/ProjectCompanyDetails2", {
    //   state: { allData: { data: data, data2: data2, data3: data3 } },
    // });
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Projects" />
        <div className="content-body">
          <ProfileNav />
          <div
            className="c-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={chevBack} alt="" />
            <p>Back</p>
          </div>
          <div className="sec-header">
            <p>Company Details</p>
            <ProgressRing
              radius={20}
              stroke={4}
              progress={100}
              text={"4/4"}
              backgroundColor="#111900"
            />
          </div>
          <div className="list-content-holder">
            <div className="content-cont">
              <p className="ov">Company achievements</p>
              {featuredWorks.map((item, index) => {
                return (
                  <>
                    <Input
                      contClass="list-input-cont cc1"
                      extraClass="list-input"
                      label="Title"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item.title}
                      onChange={(e) => {
                        handleInputChange1(index, "title", e.target.value);
                        handleFieldChange(`tt${index}`, e.target.value);
                      }}
                      error={errors[`tt${index}`]}
                      errorText={errors[`tt${index}`]}
                    />
                    <div
                      className={`img-upload-cont ${
                        item.fileName ? "img-upload-cont1" : ""
                      } ${errors[`dd${index}`] ? "img-cont-error" : ""}`}
                      onClick={() => {
                        // @ts-ignore

                        // @ts-ignore
                        if (item.link === "") {
                          // @ts-ignore
                          fileInputRefs[index].click();
                        }
                      }}
                      style={{ cursor: item.link ? "not-allowed" : "pointer" }}
                    >
                      <input
                        type="file"
                        // @ts-ignore
                        ref={(el) => (fileInputRefs[index] = el)}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileSelect(index, e)}
                      />
                      {item.fileName || isUploading ? (
                        <div className="upp-nav">
                          {item.fileName ? <p>Cover Image</p> : <div></div>}
                          {isUploading ? (
                            <ActivityIndicator size="24px" color="#a7fc00" />
                          ) : (
                            <img src={roundedCheck} />
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <img
                        src={item.fileName ? uploadedImg : uploadImg}
                        alt=""
                      />
                      <p>
                        {" "}
                        {item.fileName
                          ? "Image Uploaded Successfully"
                          : "Upload Image"}
                      </p>
                      <span
                        onClick={() => {
                          if (uploadError) {
                            // tryFileUploadAgain();
                          }
                        }}
                      >
                        {item.fileName
                          ? `${item.fileName}`
                          : uploadError
                          ? "Try again"
                          : "(375*150 recommended)"}
                      </span>
                      {item.fileName && (
                        <div className="bbb-nav">
                          <div className="nn-wrap">
                            <img src={gallary} alt="" />
                            <p>{item.fileName}</p>
                          </div>

                          <img
                            src={trash}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              removeUploadedFile(index);
                              console.log("sg");
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <Input
                      contClass="list-input-cont cc"
                      extraClass={`list-input ${
                        item.document != "" ? "cus" : ""
                      }`}
                      label="or enter document link"
                      contStyle={{
                        height: 64,
                        // cursor: item.document != "" ? "not-allowed" : "auto",
                        backgroundColor: "#0d0d0d",
                      }}
                      value={item.link}
                      labelStyle={{ fontSize: 14 }}
                      disabled={item.document != ""}
                      onChange={(e) => {
                        handleInputChange1(index, "link", e.target.value);
                        handleFieldChange(`ll${index}`, e.target.value);
                        handleFieldChange(`dd${index}`, e.target.value);
                      }}
                      error={errors[`ll${index}`]}
                      errorText={errors[`ll${index}`]}
                    />
                  </>
                );
              })}
              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddMore();
                  }}
                >
                  <img src={plus} alt="" /> Add company achievment
                </div>
              </div>
            </div>
            <div className="content-cont">
              <p className="ov">Partnerships</p>
              {partnerships.map((item, index) => {
                return (
                  <>
                    <div className="option-holders">
                      <div className="input-holder">
                        <Input
                          contClass="list-input-cont"
                          extraClass="list-input"
                          label="Partner company"
                          contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                          value={item.company}
                          onChange={(e) => {
                            handlePChange(index, "company", e.target.value);
                            handleFieldChange(`comp${index}`, e.target.value);
                          }}
                          error={errors[`comp${index}`]}
                          errorText={errors[`comp${index}`]}
                        />
                      </div>
                      <div className="input-holder">
                        <Input
                          contClass="list-input-cont"
                          extraClass="list-input"
                          label="Contract duration"
                          contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                          value={item.duration}
                          onChange={(e) => {
                            handlePChange(index, "duration", e.target.value);
                            handleFieldChange(
                              `duration${index}`,
                              e.target.value
                            );
                          }}
                          error={errors[`duration${index}`]}
                          errorText={errors[`duration${index}`]}
                        />
                      </div>
                    </div>
                    <div
                      className={`abt-talent-cont ${
                        errors[`pp${index}`] ? "img-cont-error" : ""
                      }`}
                    >
                      <div
                        className={`abt-label ${
                          isFocused === `purpose${index}` ? "abt-label1" : ""
                        }`}
                      >
                        About Company
                      </div>
                      <textarea
                        ref={textareaRef}
                        className="abt-talent-text"
                        value={item.purpose}
                        onChange={(e) => {
                          handlePChange(index, "purpose", e.target.value);
                          adjustHeight(e.target);
                          handleFieldChange(`pp${index}`, e.target.value);
                        }}
                        onFocus={() => setIsFocused(`purpose${index}`)}
                        onBlur={(e) =>
                          setIsFocused(
                            item.purpose != "" ? `purpose${index}` : ""
                          )
                        }
                      />
                    </div>
                    {errors[`pp${index}`] && (
                      <p className="error-text">{errors[`pp${index}`]}</p>
                    )}
                  </>
                );
              })}

              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddMorePartnerships();
                  }}
                >
                  <img src={plus} alt="" /> Add more
                </div>
              </div>
            </div>
            <div className="content-cont">
              <p className="ov">
                Supporting documents (business plan, Pitch Deck, Legal Documents
                etc)
              </p>
              {supportingDoc.map((item, index) => {
                return (
                  <>
                    <Input
                      contClass="list-input-cont cc1"
                      extraClass="list-input"
                      label="Title"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item.title}
                      onChange={(e) => {
                        handleInputChangeDoc(index, "title", e.target.value);
                        handleFieldChange(`tts${index}`, e.target.value);
                      }}
                      error={errors[`tts${index}`]}
                      errorText={errors[`tts${index}`]}
                    />
                    <div
                      className={`img-upload-cont ${
                        item.fileName ? "img-upload-cont1" : ""
                      } ${errors[`dds${index}`] ? "img-cont-error" : ""}`}
                      onClick={() => {
                        // @ts-ignore

                        // @ts-ignore
                        if (item.link === "") {
                          // @ts-ignore
                          fileInputRefs1[index].click();
                        }
                      }}
                      style={{ cursor: item.link ? "not-allowed" : "pointer" }}
                    >
                      <input
                        type="file"
                        // @ts-ignore
                        ref={(el) => (fileInputRefs1[index] = el)}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileSelect1(index, e)}
                      />
                      {item.fileName ||
                      (isUploading && supportingDoc[index]) ? (
                        <div className="upp-nav">
                          {item.fileName ? <p>Cover Image</p> : <div></div>}
                          {isUploading && supportingDoc[index] ? (
                            <ActivityIndicator size="24px" color="#a7fc00" />
                          ) : (
                            <img src={roundedCheck} />
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <img
                        src={item.fileName ? uploadedImg : uploadImg}
                        alt=""
                      />
                      <p>
                        {" "}
                        {item.fileName
                          ? "Image Uploaded Successfully"
                          : "Upload Image"}
                      </p>
                      <span
                        onClick={() => {
                          if (uploadError) {
                            // tryFileUploadAgain();
                          }
                        }}
                      >
                        {item.fileName
                          ? `${item.fileName}`
                          : uploadError && supportingDoc[index]
                          ? "Try again"
                          : "(375*150 recommended)"}
                      </span>
                      {item.fileName && (
                        <div className="bbb-nav">
                          <div className="nn-wrap">
                            <img src={gallary} alt="" />
                            <p>{item.fileName}</p>
                          </div>

                          <img
                            src={trash}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              removeUploadedDoc(index);
                              console.log("sg");
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <Input
                      contClass="list-input-cont cc"
                      extraClass={`list-input ${
                        item.document != "" ? "cus" : ""
                      }`}
                      label="or enter document link"
                      contStyle={{
                        height: 64,
                        // cursor: item.document != "" ? "not-allowed" : "auto",
                        backgroundColor: "#0d0d0d",
                      }}
                      value={item.link}
                      labelStyle={{ fontSize: 14 }}
                      disabled={item.document != ""}
                      onChange={(e) => {
                        handleInputChangeDoc(index, "link", e.target.value);
                        handleFieldChange(`lls${index}`, e.target.value);
                        handleFieldChange(`dds${index}`, e.target.value);
                      }}
                      error={errors[`lls${index}`]}
                      errorText={errors[`lls${index}`]}
                    />
                  </>
                );
              })}

              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddMoreSp();
                  }}
                >
                  <img src={plus} alt="" /> Add document
                </div>
              </div>
            </div>
            <div className="content-cont">
              <p className="ov">Key stakeholders</p>
              {stakeHolder.map((item, index) => {
                return (
                  <div className="skills-cont">
                    <div className="skill-input">
                      <Input
                        contClass="list-input-cont"
                        extraClass="list-input"
                        label="Name"
                        contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                        value={item.name}
                        onChange={(e) => {
                          handleSHChange(index, "name", e.target.value);
                          handleFieldChange(`n${index}`, e.target.value);
                        }}
                        error={errors[`n${index}`]}
                        errorText={errors[`n${index}`]}
                      />
                    </div>
                    <div className="skill-input">
                      <Input
                        contClass="list-input-cont"
                        extraClass="list-input"
                        label="Role"
                        contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                        value={item.role}
                        onChange={(e) => {
                          handleSHChange(index, "role", e.target.value);
                          handleFieldChange(`r${index}`, e.target.value);
                        }}
                        error={errors[`r${index}`]}
                        errorText={errors[`r${index}`]}
                      />
                    </div>
                    <div className="skill-input">
                      <Input
                        contClass="list-input-cont"
                        extraClass="list-input"
                        label="LinkedIn URL"
                        contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                        value={item.url}
                        onChange={(e) => {
                          handleSHChange(index, "url", e.target.value);
                          handleFieldChange(`url${index}`, e.target.value);
                        }}
                        error={errors[`url${index}`]}
                        errorText={errors[`url${index}`]}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddSh();
                  }}
                >
                  <img src={plus} alt="" /> Add stakeholder
                </div>
              </div>
            </div>

            <div className="content-cont">
              <p className="ov">Company contact</p>
              <div className="option-holders">
                <div className="input-holder">
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input"
                    label="Website URL"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={webUrl}
                    onChange={(e) => {
                      setWebUrl(e.target.value);
                      handleFieldChange("webUrl", e.target.value);
                    }}
                    error={errors.webUrl}
                    errorText={errors.webUrl}
                  />
                </div>
                <div className="input-holder">
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input"
                    label="LinkedIn URL"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={LinkedInUrl}
                    onChange={(e) => {
                      setLinkedInUrl(e.target.value);
                      handleFieldChange("LinkedInUrl", e.target.value);
                    }}
                    error={errors.LinkedInUrl}
                    errorText={errors.LinkedInUrl}
                  />
                </div>
              </div>
              <div className="option-holders">
                <div className="input-holder">
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input"
                    label="Email Address"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleFieldChange("email", e.target.value);
                    }}
                    error={errors.email}
                    errorText={errors.email}
                  />
                </div>
                {/* <div className="input-holder">
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input"
                    label="Contact Number"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={cnum}
                    onChange={(e) => {
                      setCnum(e.target.value);
                      handleFieldChange("cnum", e.target.value);
                    }}
                    error={errors.cnum}
                    errorText={errors.cnum}
                  />
                </div> */}
              </div>
            </div>

            <div className="std-cont">
              <div className="std-btn">Save to draft</div>
              <button
                className={`c-btn ${loading ? "c-disabled" : ""}`}
                disabled={loading}
                onClick={handleFormSubmit}
              >
                {loading ? (
                  <div className="dot-loader">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                ) : (
                  "List project"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails2;
