import React from "react";
import envelop from "../../assets/envelop.gif";
import SecBtn from "../../Components/SecBtn";
import { useNavigate } from "react-router-dom";
import tick from "../../assets/tick.gif";

export default function VoucherDeleted() {
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={tick} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Voucher deleted</h4>
        <p>Voucher JP-24-MJOO26 has been deleted permanently.</p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Back to vouchers"
          onPress={() => {
            navigate("/Voucher");
          }}
        />
      </div>
    </section>
  );
}
