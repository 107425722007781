import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../../Components/SideNav";
import ProfileNav from "../../../Components/ProfileNav";
import chevBack from "../../../assets/ion_chevron-back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressRing from "../../../Components/ProgressRing";
import Input from "../../../Components/Input";
import uploadImg from "../../../assets/upload.svg";
import chevDown from "../../../assets/chev-down.svg";
import { GetIndustries } from "../../../RequestHandlers/Auth";
import colon from "../../../assets/colon.svg";
import NumberMonthDropdown from "../../../Components/NumberMonthDropdown";
import plus from "../../../assets/plus.svg";
import TextArea from "../../../Components/Textera";
import { useFileUpload } from "../../../context/uploadFile";
import roundedCheck from "../../../assets/rc.svg";
import uploadedImg from "../../../assets/uploadedImg.svg";
import gallary from "../../../assets/gallery.svg";
import trash from "../../../assets/trash.svg";
import ActivityIndicator from "../../../Components/ActivityIndicator";

interface Row {
  months: string;
  roi: string;
}
interface fwork {
  title: string;
  document: string;
  link: string;
  fileName?: string;
}

function CompanyDetails() {
  const navigate = useNavigate();
  const locat = useLocation();
  const data = locat.state.data || {};
  const data2 = locat.state.data2 || {};
  const [uploading, setUpLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadedFile, setUpLoadedFile] = useState<any>(null);
  const fileInputRef = useRef(null);
  const [ceoName, setCeoName] = useState("");
  const [ceoLinkdin, setCeoLinkdin] = useState("");
  const [abtComp, setAbtComp] = useState("");
  const [compWeburl, setCompWeburl] = useState("");
  const [location, setLocation] = useState("");
  const [compSize, setCompSize] = useState("");
  const [mp, setMp] = useState("");
  const [mission, setMission] = useState("");
  const [vision, setVision] = useState("");
  const [strategy, setStrategy] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState("");
  const [errors, setErrors] = useState<any>({});
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const urlRegex = /^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/.*)?$/;

  const [featuredWorks, setFeaturedWorks] = useState<fwork[]>([
    { title: "", document: "", link: "", fileName: "" },
  ]);
  const handleAddMore = () => {
    setFeaturedWorks([
      ...featuredWorks,
      { title: "", document: "", link: "", fileName: "" },
    ]);
  };
  const handleInputChange1 = (
    index: number,
    field: keyof fwork,
    value: string
  ) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              [field]: value,
              ...(field === "link" ? { document: "" } : {}),
              ...(field === "document" ? { link: "" } : {}),
            }
          : item
      )
    );
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const handleFileSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file); // Replace with your actual upload logic
        console.log("Uploaded file:", data);

        if (data.secure_url) {
          // setSupportingDoc((prev) =>
          //   prev.map((item, idx) =>
          //     idx === index ? { ...item, document: data.secure_url } : item
          //   )
          // );
          handleInputChange1(index, "document", data.secure_url);
          setFeaturedWorks((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    document: data.secure_url,
                    fileName: file.name, // Add the file name
                    link: "", // Clear the link field
                  }
                : item
            )
          );
          handleFieldChange(`dd${index}`, data.secure_url);
          handleFieldChange(`ll${index}`, data.secure_url);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const fileInputRefs = useRef<HTMLInputElement[]>([]);
  // Initialize refs
  useEffect(() => {
    fileInputRefs.current = featuredWorks.map(
      (_, i) => fileInputRefs.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };

  const removeUploadedFile = (index: number) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, document: "", fileName: "" } : item
      )
    );

    // console.log(supportingDoc.splice(index,1));
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    if (ceoName.trim() === "") {
      newErrors.ceoName = "Field is required";
    }
    if (ceoLinkdin.trim() === "") {
      newErrors.ceoLinkdin = "Field is required";
    } else if (!urlRegex.test(ceoLinkdin)) {
      newErrors.ceoLinkdin = "Invalid URL format";
    }
    if (abtComp.trim() === "") {
      newErrors.abtComp = "Field is required";
    }
    if (compWeburl.trim() === "") {
      newErrors.compWeburl = "Field is required";
    } else if (!urlRegex.test(compWeburl)) {
      newErrors.compWeburl = "Invalid URL format";
    }
    if (location.trim() === "") {
      newErrors.location = "Field is required";
    }
    if (compSize.trim() === "") {
      newErrors.compSize = "Field is required";
    }
    if (mp.trim() === "") {
      newErrors.mp = "Field is required";
    }
    if (mission.trim() === "") {
      newErrors.mission = "Field is required";
    }
    if (vision.trim() === "") {
      newErrors.vision = "Field is required";
    }
    if (strategy.trim() === "") {
      newErrors.strategy = "Field is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const data3 = {
      ceoName: ceoName,
      ceoLinkdin: ceoLinkdin,
      abtComp: abtComp,
      compWeburl: compWeburl,
      location: location,
      compSize: compSize,
      mp: mp,
      mission: mission,
      vision: vision,
      strategy: strategy,
      portfolio: featuredWorks,
    };
    console.log("submited", {
      data,
      data2,
      data3,
    });
    setErrors({});
    navigate("/CompanyDetails2", {
      state: { allData: { data: data, data2: data2, data3: data3 } },
    });
  };
  return (
    <div className="dash-body">
      <SideNav activeTab="Projects" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>Company Details</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={75}
            text={"3/4"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <div className="option-holders" style={{ marginTop: 0 }}>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="CEO’s Name"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={ceoName}
                  onChange={(e) => {
                    setCeoName(e.target.value);
                    handleFieldChange("ceoName", e.target.value);
                  }}
                  error={errors.ceoName}
                  errorText={errors.ceoName}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="CEO’s  Linkedin URL "
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={ceoLinkdin}
                  onChange={(e) => {
                    setCeoLinkdin(e.target.value);
                    handleFieldChange("ceoLinkdin", e.target.value);
                  }}
                  error={errors.ceoLinkdin}
                  errorText={errors.ceoLinkdin}
                />
              </div>
            </div>
            <div className="option-holders">
              <div
                className={`abt-talent-cont ${
                  errors.abtComp ? "img-cont-error" : ""
                }`}
                style={{ marginTop: 0 }}
              >
                <div
                  className={`abt-label ${
                    isFocused === "abt-comp" ? "abt-label1" : ""
                  }`}
                >
                  About Company
                </div>
                <textarea
                  ref={textareaRef}
                  className="abt-talent-text"
                  value={abtComp}
                  onChange={(e) => {
                    setAbtComp(e.target.value);
                    adjustHeight(e.target);
                    handleFieldChange("abtComp", e.target.value);
                  }}
                  onFocus={() => setIsFocused("abt-comp")}
                  onBlur={(e) => setIsFocused(abtComp != "" ? "abt-comp" : "")}
                />
              </div>
            </div>
            {errors.abtComp && <p className="error-text">{errors.abtComp}</p>}
            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Company Website URL"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={compWeburl}
                  onChange={(e) => {
                    setCompWeburl(e.target.value);
                    handleFieldChange("compWeburl", e.target.value);
                  }}
                  error={errors.compWeburl}
                  errorText={errors.compWeburl}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Office Location"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    handleFieldChange("location", e.target.value);
                  }}
                  error={errors.location}
                  errorText={errors.location}
                />
              </div>
            </div>
            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Company Size (e.g 11-50 employees)"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={compSize}
                  onChange={(e) => {
                    setCompSize(e.target.value);
                    handleFieldChange("compSize", e.target.value);
                  }}
                  error={errors.compSize}
                  errorText={errors.compSize}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Market Presence (e.g Nigeria)"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={mp}
                  onChange={(e) => {
                    setMp(e.target.value);
                    handleFieldChange("mp", e.target.value);
                  }}
                  error={errors.mp}
                  errorText={errors.mp}
                />
              </div>
            </div>
          </div>

          <div className="content-cont">
            <p className="ov">Company profile</p>
            <div
              className={`abt-talent-cont ${
                errors.mission ? "img-cont-error" : ""
              }`}
            >
              <div
                className={`abt-label ${
                  isFocused === "mission" ? "abt-label1" : ""
                }`}
              >
                Mission
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={mission}
                onChange={(e) => {
                  setMission(e.target.value);
                  adjustHeight(e.target);
                  handleFieldChange("mission", e.target.value);
                }}
                onFocus={() => setIsFocused("mission")}
                onBlur={(e) => setIsFocused("")}
              />
            </div>
            {errors.mission && <p className="error-text">{errors.mission}</p>}
            <div
              className={`abt-talent-cont ${
                errors.vision ? "img-cont-error" : ""
              }`}
            >
              <div
                className={`abt-label ${
                  isFocused === "vision" ? "abt-label1" : ""
                }`}
              >
                Vision
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={vision}
                onChange={(e) => {
                  setVision(e.target.value);
                  adjustHeight(e.target);
                  handleFieldChange("vision", e.target.value);
                }}
                onFocus={() => setIsFocused("vision")}
                onBlur={(e) => setIsFocused("")}
              />
            </div>
            {errors.vision && <p className="error-text">{errors.vision}</p>}
            <div
              className={`abt-talent-cont ${
                errors.strategy ? "img-cont-error" : ""
              }`}
            >
              <div
                className={`abt-label ${
                  isFocused === "strategy" ? "abt-label1" : ""
                }`}
              >
                Strategy
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={strategy}
                onChange={(e) => {
                  setStrategy(e.target.value);
                  handleFieldChange("strategy", e.target.value);
                  adjustHeight(e.target);
                }}
                onFocus={() => setIsFocused("strategy")}
                onBlur={(e) => setIsFocused("")}
              />
            </div>
            {errors.strategy && <p className="error-text">{errors.strategy}</p>}
          </div>
          <div className="content-cont">
            <p className="ov">Company portfolio (past work)</p>
            {featuredWorks.map((item, index) => {
              return (
                <>
                  <Input
                    contClass="list-input-cont cc1"
                    extraClass="list-input"
                    label="Title"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={item.title}
                    onChange={(e) => {
                      handleInputChange1(index, "title", e.target.value);
                      handleFieldChange(`tt${index}`, e.target.value);
                    }}
                    error={errors[`tt${index}`]}
                    errorText={errors[`tt${index}`]}
                  />
                  <div
                    className={`img-upload-cont ${
                      item.fileName ? "img-upload-cont1" : ""
                    } ${errors[`dd${index}`] ? "img-cont-error" : ""}`}
                    onClick={() => {
                      // @ts-ignore

                      // @ts-ignore
                      if (item.link === "") {
                        // @ts-ignore
                        fileInputRefs[index].click();
                      }
                    }}
                    style={{ cursor: item.link ? "not-allowed" : "pointer" }}
                  >
                    <input
                      type="file"
                      // @ts-ignore
                      ref={(el) => (fileInputRefs[index] = el)}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(index, e)}
                    />
                    {item.fileName || isUploading ? (
                      <div className="upp-nav">
                        {item.fileName ? <p>Cover Image</p> : <div></div>}
                        {isUploading ? (
                          <ActivityIndicator size="24px" color="#a7fc00" />
                        ) : (
                          <img src={roundedCheck} />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <img src={item.fileName ? uploadedImg : uploadImg} alt="" />
                    <p>
                      {" "}
                      {item.fileName
                        ? "Image Uploaded Successfully"
                        : "Upload Image"}
                    </p>
                    <span
                      onClick={() => {
                        if (uploadError) {
                          // tryFileUploadAgain();
                        }
                      }}
                    >
                      {item.fileName
                        ? `${item.fileName}`
                        : uploadError
                        ? "Try again"
                        : "(375*150 recommended)"}
                    </span>
                    {item.fileName && (
                      <div className="bbb-nav">
                        <div className="nn-wrap">
                          <img src={gallary} alt="" />
                          <p>{item.fileName}</p>
                        </div>

                        <img
                          src={trash}
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeUploadedFile(index);
                            console.log("sg");
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <Input
                    contClass="list-input-cont cc"
                    extraClass={`list-input ${
                      item.document != "" ? "cus" : ""
                    }`}
                    label="or enter document link"
                    contStyle={{
                      height: 64,
                      // cursor: item.document != "" ? "not-allowed" : "auto",
                      backgroundColor: "#0d0d0d",
                    }}
                    value={item.link}
                    labelStyle={{ fontSize: 14 }}
                    disabled={item.document != ""}
                    onChange={(e) => {
                      handleInputChange1(index, "link", e.target.value);
                      handleFieldChange(`ll${index}`, e.target.value);
                      handleFieldChange(`dd${index}`, e.target.value);
                    }}
                    error={errors[`ll${index}`]}
                    errorText={errors[`ll${index}`]}
                  />
                </>
              );
            })}

            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddMore();
                }}
              >
                <img src={plus} alt="" /> Add more
              </div>
            </div>
          </div>
          <div className="std-cont">
            <div className="std-btn">Save to draft</div>
            <div className="c-btn" onClick={handleFormSubmit}>
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
