import "../../css/notification/style.css"
const NotificationTabHeader = ({ activeOption, setActiveOption }: any) => {
    const options = [
        {
            name: "All",
            value: "all"
        },
        {
            name: "Unread",
            value: "unread"
        },
        {
            name: "Read",
            value: "read"
        },
    ]
    return (
        <>
            <div className="notification-tab-header-view" >
                <div className="notification-tab-header-container" >
                    {options.map(({ name, value }) => (
                        <div onClick={() => {
                            setActiveOption(value)
                        }} style={{
                            backgroundColor: activeOption === value ? "#FFFFFF" : "transparent"
                        }} key={value} className="notification-tab-option" >
                            <p style={{
                                color: activeOption === value ? "#030500" : "#A5A5A5"
                            }} >{name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default NotificationTabHeader;