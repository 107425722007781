import React, { useEffect, useRef, useState } from "react";
import "../css/style.css";
import "../css/responsive.css";
import NavBar from "../Components/NavBar";
import FadeInAnimation from "../Components/FadeIn";
import TyperAnimation from "../Components/TyperAnimation";
import PrimaryBtn from "../Components/PrimaryBtn";
import AnalyticImg from "../assets/AnalyticsImg.webp";
import DashboardImg from "../assets/DashboardImg.webp";
import { useNavigate } from "react-router-dom";
// import flick from "../assets/partners/flick.svg";
// import stb from "../assets/partners/stb.svg";
import tbsBanner from "../assets/tbsBanner.webp";
import { motion } from "framer-motion";
import Footer from "../Components/FooterNew";
import { useMediaQuery } from "react-responsive";
import lisk from "../assets/partners/lisk.png";
import stb from "../assets/partners/stableblocks.png";
import tradlander from "../assets/partners/tradelenda.png";
import cv from "../assets/partners/cv labs.png";
import flick from "../assets/partners/flick.png";
import afirk from "../assets/partners/afriskaut.png";
import mBanner from "../assets/mBanner.png"
import kai from '../assets/partners/kai.png'
import moni from '../assets/partners/moni.png'

export default function HomeScreen() {
  const images = [DashboardImg, AnalyticImg];
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 567 });
  const typerText = [
    "List your projects on StableBlocks.",
    "Track your projects' success.",
    "Get tools for visibility and funding.",
  ];
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [fadeKey, setFadeKey] = useState(0);
  const [move, setMove] = useState("0");

  useEffect(() => {
    // Switch images every 5 seconds
    const interval = setInterval(() => {
      setFadeKey((prevKey) => prevKey + 1);
      setCurrentImage((prevImage) => {
        const nextIndex = (images.indexOf(prevImage) + 1) % images.length;
        return images[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(()=>{
    setMove("50%")
    setTimeout(() => {
    }, 5000);
  },[])
  // const partners = [lisk, kai, moni, flick, afirk, tradlander, stb, cv];
  const partners = [lisk, tradlander, flick, afirk, stb, cv];
  
  const partnersImg = [
  <img src={lisk} alt=""  className={`prt-img`} />,
  <img src={tradlander} alt=""  className={`prt-img`} />,
  <img src={flick} alt=""  className={`prt-img`} />,
  <img src={afirk} alt=""  className={`prt-img`} />,
  <img src={stb} alt=""  className={`prt-img2`} />,
  <img src={cv} alt=""  className={`prt-img3`} />,

];
  const InfiniteScroll = () => {    
    return (
      <div className="partners-container">
        {/* <div className="partners mt-[40px] w-[100%] flex justify-center items-center gap-[40px]"> */}
        <div className="partners mt-[40px] w-[100%] flex  items-center gap-[40px]" >
         
          {[...partnersImg, ...partnersImg, ...partnersImg, ...partnersImg].map((item, index) => (
            item
          ))}
        </div>
      </div>
    );
  };

  const MovingBox = () => {
    const [move, setMove] = useState("0%");
    const [shouldMove, setShouldMove] = useState(true); // Control when transition happens
  
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setMove("-100%"); // Move once and stay there
      }, 1000); // Delay to ensure initial render
  
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }, []);
  
  
    return (
      <div className="partners-container">
      <div
        className="w-[100%]"
        style={{
          height: "100px",
          background: "tomato",
          transition: "transform 10s linear",
          transform: `translateX(${move})`, // Move and stay at -120%
        }}
      />
    </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="body home-body">
        <NavBar currentActive="About TBS" />

        <FadeInAnimation delay={1.5} duration={1}>
          <div className="textCard">
            <p># We help you fund your dreams</p>
          </div>
        </FadeInAnimation>
        <FadeInAnimation delay={2.5} duration={1}>
          <h4 className="bannerText">
            The all-in-one {!isMobile && <br />} tokenization platform
          </h4>
        </FadeInAnimation>
        {/* <FadeInAnimation delay={3} duration={1}>
          <TyperAnimation
            sentences={typerText}
            speed={150}
            pause={2000}
            // className="typerText"
          />
        </FadeInAnimation> */}
        <FadeInAnimation delay={3.5} duration={1}>
          <div className="desTextCont">
            <div className="desTextContInner">
              <p className="desText">
                Incorporate a DAO, manage tokens and taxes, stay compliant - all{" "}
                {!isMobile && <br />}
                in one place.
              </p>
            </div>
          </div>
        </FadeInAnimation>
        <FadeInAnimation delay={4} duration={1}>
          <div className="btnWrap">
            <PrimaryBtn
              // text={"Tokenize with us"}
              text={"Get started"}
              onPress={() => {
                navigate("/tokenizewithus");
                // navigate("/Voucher");
              }}
            />
          </div>
        </FadeInAnimation>
        <FadeInAnimation delay={4.5} duration={1}>
          {/* <div className="partners-sec mt-[84px] w-[100%]"> */}
            {/* <p className="text-center text-white text-[20px] orp">
              Our Partners
            </p> */}
            {/* <div
              className={`partners mt-[40px] w-[100%] flex justify-center items-center gap-[40px] ${
                partners.length > 10 || isMobile ? "marquee" : ""
              }`}
            >
              {partners.map((item, index) => (
                <img
                  src={item}
                  alt=""
                  key={index}
                  className={`prt-img ${index === 4 ? "prt-img2" : index === 5 ? "prt-img3" : ""}`}
                />
              ))}
            </div> */}
          {/* </div> */}

          <InfiniteScroll/>
          {/* <MovingBox/> */}
        </FadeInAnimation>
        <motion.img
          initial={{ opacity: 0, scale: 0.3 }}
          whileInView={{
            opacity: 1,
            scale: 1,
            transition: {
              duration: 1,
            },
          }}
          viewport={{
            once: true,
          }}
          src={ isMobile ? mBanner :tbsBanner}
          alt=""
          className="w-[100%] mt-[124px] bn-img"
        />
      </div>
      <div className="b1-sec">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          className="mt-[124px] w-[480px] self-start bby"
        >
          <p className="text-white text-[16px] font-normal" style={{color:"#A7FC00"}}>
            Tokenize and Scale
          </p>
          <h4 className="text-white text-[40px] font-semibold">
            Everything you need to fund your vision.
          </h4>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          className="mt-[64px] self-start bk"
        >
          <p className="text-white text-[20px] font-semibold">Backed by</p>
          <div className="flex items-center gap-[40px] mt-[25px] backed-by">
            <img src={cv} alt="" />
            <img src={lisk} alt="" />
          </div>
        </motion.div>
      </div>
      {<Footer/>}
      {/* <div className="footer mt-[124px] w-[100%]">
        <Footer />
        {isMobile && <div className="text-[110px] trx">Trendx</div>}
        {isMobile && (
          <div className="copyright-holder">
            <p className="text-[0.75rem] text-white copyright">
              © 2024 All Rights Reserved
            </p>
          </div>
        )}
      </div> */}
    </>
  );
}
