import React, { useState } from "react";
import mClose from "../assets/close.svg";
import { DeleteVoucherByID } from "../RequestHandlers/Voucher";
import Toast, { handleToast } from "./Toast";
import { useNavigate } from "react-router-dom";

interface PProps {
  onClose?: any;
  cancel?: any;
  onDelete?: any;
  code: string;
  id: string;
}
function DeleteVoucher({ onClose, cancel, onDelete, code, id }: PProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const deleteVoucher = async () => {
    try {
      setLoading(true);
      const res = await DeleteVoucherByID(id);
      if (res) {
       navigate("/VoucherDeleted")
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="del-promt">
      <Toast />
      <div className="del-promt-nav">
        <p>Delete voucher</p>
        <button style={{ all: "unset", cursor: "pointer" }} onClick={onClose}>
          <img src={mClose} alt="" />
        </button>
      </div>
      <p className="del-des">
        Are you sure you want to delete voucher {code}? This action is permanent
        and cannot be undone.
      </p>
      <div className="dd-btns del-btns">
        <button onClick={cancel}>Cancel</button>
        <button
          onClick={() => {
            deleteVoucher();
          }}
        >
          Delete voucher
        </button>
      </div>
    </div>
  );
}

export default DeleteVoucher;
