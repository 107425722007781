export function calculateEndDate(
    startDateString: string,
    durationInDays: number
  ): string {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    // Parse the start date
    const startDate = new Date(startDateString);
    // Add the duration to the start date
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + durationInDays);
    // Format the end date
    const month = months[endDate.getMonth()];
    const day = endDate.getDate().toString().padStart(2, "0");
    const year = endDate.getFullYear();

    return `${month} ${day}, ${year} ` || "...";
  }