import React, { useState, useEffect, useContext } from "react";
import HeaderBar from "../Components/HeaderBar";
import { useNavigate } from "react-router-dom";
import Input from "../Components/Input";
import FadeInAnimation from "../Components/FadeIn";
import SecBtn from "../Components/SecBtn";
import Cookies from "js-cookie";
import { CredentailsContext } from "../context/CredentailContext";
import Toast, { handleToast } from "../Components/Toast";
import { Login } from "../RequestHandlers/Auth";

export default function LoginScreen() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { storedCredentails, setStoredCredentails } =
    useContext(CredentailsContext);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    return password.trim() !== "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail(value);

    if (validateEmail(value)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const persistLogin = (credentials: any) => {
    try {
      Cookies.set("webCook", JSON.stringify(credentials), { expires: 2 });
      // @ts-ignore
      setStoredCredentails(credentials);
      setTimeout(() => {
        navigate("/Dashboard");
      }, 2000);
    } catch (err) {
      console.error("Error storing credentials:", err);
    }
  };

  const login = async () => {
    setLoading(true);
    try {
      const body = {
        email: email.trim(),
        password: password.trim(),
      };
      const res = await Login(body);
      console.log(res);
      
      if (res.token) {
        persistLogin(res.token);
        setLoading(false);
        handleToast("Login successful", "success");
      } else {
        setLoading(false);
        handleToast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
      handleToast("Unknown error", "error")
    } finally {
      setLoading(false);
    }
  };
  const handlePasswordChange = (e: any) => {
    const value = e.target.value;
    setPassword(value);
    if (validatePassword(value)) {
      setPasswordError("");
    } else {
      setPasswordError("Password cannot be empty.");
    }
  };

  const handleSubmit = async () => {
    if (!emailError && !passwordError) {
      // console.log("Login successful");
      // navigate("/AccountCreatedScreen");
      login();
    }
  };

  return (
    <>
      <Toast />
      <div className="tk-reg-body">
        <HeaderBar
          onclick={() => {
            navigate(-1);
          }}
        />
        
        <div className="pageCont mTop40">
          <FadeInAnimation delay={0.3} duration={1}>
            <h4 className="pageH4">Welcome back</h4>
          </FadeInAnimation>
          <FadeInAnimation delay={0.6} duration={1}>
            <p className="pageP">Log in to your TX Business Suite account</p>
          </FadeInAnimation>

          <div className="inputCont1">
            <FadeInAnimation delay={0.9} duration={1}>
              <Input
                type="text"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                error={emailError !== "" ? true : false}
                errorText={emailError}
              />
            </FadeInAnimation>
          </div>

          <div className="inputCont1">
            <FadeInAnimation delay={1.2} duration={1}>
              <Input
                isPassword={true}
                type="text"
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                error={passwordError !== "" ? true : false}
                errorText={passwordError}
              />
            </FadeInAnimation>
          </div>

          <div className="fp">
            <FadeInAnimation delay={1.5}>
              <p
                onClick={() => {
                  navigate("/ForgotPassword");
                }}
              >
                Forgot password?
              </p>
            </FadeInAnimation>
          </div>

          <div className="bottomCont">
            <FadeInAnimation delay={1.8} duration={1}>
              <SecBtn
                text="Continue"
                onPress={handleSubmit}
                disabled={
                  emailError !== "" ||
                  passwordError !== "" ||
                  !email ||
                  !password
                }
                loading={loading}
              />
            </FadeInAnimation>
          </div>
        </div>
      </div>
    </>
  );
}
