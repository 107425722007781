import "../../css/profile/styles.css";
import toggleOn from "../../assets/toggle-on.svg";
import toggleOff from "../../assets/toggle-off.svg";
import { useEffect, useState } from "react";
import { GetUserNotificationPreference, UpdateUseNotificationPreference } from "../../RequestHandlers/User";
import Toast, { handleToast } from "../Toast";
import FadeInAnimation from "../FadeIn";

const NotificationPreference = () => {
    const [preferences, setPreferences] = useState([
        {
            name: "In-app notifications",
            value: "in-app",
            isActive: false,
            border: true,
        },
        {
            name: "Email notifications",
            value: "email",
            isActive: false,
            border: false,
        },
    ]);

    const [customizeAlerts, setCustomizeAlerts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const togglePreference = (index: number) => {
        setPreferences((prevPreferences) =>
            prevPreferences.map((pref, i) =>
                i === index ? { ...pref, isActive: !pref.isActive } : pref
            )
        );
    };

    const toggleCustomizeAlerts = async (index: number) => {
        if (isLoading) return;

        const alert: any = customizeAlerts[index];
        const updatedStatus = !alert.isActive;

        try {
            setIsLoading(true);
            await UpdateUseNotificationPreference({
                id: alert.id,
                isActive: updatedStatus,
            });

            setCustomizeAlerts((prevAlerts: any) =>
                prevAlerts.map((pref: any, i: any) =>
                    i === index ? { ...pref, isActive: updatedStatus } : pref
                )
            );
            handleToast(" Notification preference updated", "success")
        } catch (error) {
            console.error("Failed to update notification preference:", error);
            handleToast("Failed to update notification preference. Please try again.", "error")

        } finally {
            setIsLoading(false);
        }
    };

    const getUserPreferenceHandler = async () => {
        try {
            setIsLoading(true);
            const response = await GetUserNotificationPreference();
            setCustomizeAlerts(response);

        } catch (error) {
            console.error("Error fetching user preferences:", error);
            alert("Error loading preferences. Please refresh the page.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUserPreferenceHandler();
    }, []);

    return (
        <div className="preference-view">
            <div className="preference-container">
                <p className="preference-title">Preference</p>
                <div className="preference-list-view">
                    {preferences.map(({ name, isActive, border }, index) => (
                        <FadeInAnimation delay={index * 0.1} duration={0.5} >
                            <div
                                key={name}
                                className="preference-list"
                                style={{
                                    paddingBottom: border ? "17px" : "0px",
                                    borderBottom: border ? "1px solid #343434 " : "none",
                                }}
                                onClick={() => togglePreference(index)}
                            >
                                <p>{name}</p>
                                <img
                                    src={isActive ? toggleOn : toggleOff}
                                    alt={`${name} toggle`}
                                />
                            </div>
                        </FadeInAnimation>
                    ))}
                </div>
            </div>

            <div style={{ marginTop: 24 }} className="preference-container">
                <p className="preference-title">Customize alerts</p>
                <div className="preference-list-view">
                    {customizeAlerts.map(({ name, isActive, id }, index) => (
                        <FadeInAnimation delay={index * 0.1} duration={0.5} >
                            <div
                                key={id}
                                className="preference-list"
                                style={{
                                    paddingBottom:
                                        index < customizeAlerts.length - 1 ? "17px" : "0px",
                                    borderBottom:
                                        index < customizeAlerts.length - 1
                                            ? "1px solid #343434"
                                            : "none",
                                }}
                                onClick={() => toggleCustomizeAlerts(index)}
                            >
                                <p>{name}</p>
                                <img
                                    src={isActive ? toggleOn : toggleOff}
                                    alt={`${name} toggle`}
                                    style={{ opacity: isLoading ? 0.5 : 1 }}
                                />
                            </div>
                        </FadeInAnimation>
                    ))}
                </div>
            </div>
            <Toast />
        </div>
    );
};

export default NotificationPreference;
