import React, { CSSProperties } from "react";
import FadeInAnimation from "./FadeIn";
interface PProps {
  loading?: true | false;
  text1?: string;
  icon?: any;
  bottomComp?: any;
  delay?: number;
  extraClass?: any
}

function ValuesCard({ loading, text1, icon, bottomComp, delay, extraClass}: PProps) {
  return (
    <div className={`card-item ${extraClass}`}>
      <FadeInAnimation duration={1} delay={delay}>
        <div className="t1-holder">
          <p>{text1}</p>
          <img src={icon} alt="" />
        </div>
      </FadeInAnimation>
      <FadeInAnimation duration={1} delay={delay}>
        {loading ? <div className="sk-load"></div> : bottomComp}
      </FadeInAnimation>
    </div>
  );
}

export default ValuesCard;
