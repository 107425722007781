import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFileUpload } from "../../../context/uploadFile";
import SideNav from "../../../Components/SideNav";
import ProfileNav from "../../../Components/ProfileNav";
import ProgressRing from "../../../Components/ProgressRing";
import chevBack from "../../../assets/ion_chevron-back.svg";
import roundedCheck from "../../../assets/rc.svg";
import uploadedImg from "../../../assets/uploadedImg.svg";
import gallary from "../../../assets/gallery.svg";
import trash from "../../../assets/trash.svg";
import ActivityIndicator from "../../../Components/ActivityIndicator";
import Input from "../../../Components/Input";
import uploadImg from "../../../assets/upload.svg";
import plus from "../../../assets/plus.svg";
interface fwork {
  title: string;
  document: string;
  link: string;
  fileName?: string;
}

function CreateOwnerSpotlight2() {
  const navigate = useNavigate();
  const locat = useLocation();
  const [uploading, setUpLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadedFile, setUpLoadedFile] = useState<any>(null);
  const fileInputRef = useRef(null);
  const [ceoName, setCeoName] = useState("");
  const [ceoLinkdin, setCeoLinkdin] = useState("");
  const [abtComp, setAbtComp] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [LinkedInUrl, setLinkedInUrl] = useState("");
  const [email, setEmail] = useState("");
  const [cnum, setCnum] = useState("");
  const [errors, setErrors] = useState<any>({});
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const urlRegex = /^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/.*)?$/;
  const [loading, setLoading] = useState(false);

  const [featuredWorks, setFeaturedWorks] = useState<fwork[]>([
    { title: "", document: "", link: "", fileName: "" },
  ]);
  const [archievements, setArchievements] = useState<fwork[]>([
    { title: "", document: "", link: "", fileName: "" },
  ]);
  const handleAddMore = () => {
    setFeaturedWorks([
      ...featuredWorks,
      { title: "", document: "", link: "", fileName: "" },
    ]);
  };
  const handleInputChange = (
    index: number,
    field: keyof fwork,
    value: string
  ) => {
    setArchievements((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              [field]: value,
              ...(field === "link" ? { document: "" } : {}),
              ...(field === "document" ? { link: "" } : {}),
            }
          : item
      )
    );
  };
  const handleInputChange1 = (
    index: number,
    field: keyof fwork,
    value: string
  ) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              [field]: value,
              ...(field === "link" ? { document: "" } : {}),
              ...(field === "document" ? { link: "" } : {}),
            }
          : item
      )
    );
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };

  const fileInputRefs = useRef<HTMLInputElement[]>([]);
  const fileInputRefs1 = useRef<HTMLInputElement[]>([]);
  // Initialize refs
  useEffect(() => {
    fileInputRefs.current = featuredWorks.map(
      (_, i) => fileInputRefs.current[i] || React.createRef<HTMLInputElement>()
    );
    fileInputRefs1.current = archievements.map(
      (_, i) => fileInputRefs1.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFileSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file); // Replace with your actual upload logic
        console.log("Uploaded file:", data);

        if (data.secure_url) {
          handleInputChange1(index, "document", data.secure_url);
          setFeaturedWorks((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    document: data.secure_url,
                    fileName: file.name, // Add the file name
                    link: "", // Clear the link field
                  }
                : item
            )
          );
          handleFieldChange(`dd${index}`, data.secure_url);
          handleFieldChange(`ll${index}`, data.secure_url);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const handleFileSelect1 = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file); // Replace with your actual upload logic
        console.log("Uploaded file:", data);

        if (data.secure_url) {
          handleInputChange(index, "document", data.secure_url);
          setArchievements((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    document: data.secure_url,
                    fileName: file.name, // Add the file name
                    link: "", // Clear the link field
                  }
                : item
            )
          );
          handleFieldChange(`dd${index}`, data.secure_url);
          handleFieldChange(`ll${index}`, data.secure_url);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };

  const removeUploadedFile = (index: number) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, document: "", fileName: "" } : item
      )
    );
  };
  const removeUploadedFile1 = (index: number) => {
    setArchievements((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, document: "", fileName: "" } : item
      )
    );
  };

  return (
    <div className="dash-body">
      <SideNav activeTab="Spotlight" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>Create Spotlight Request</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={100}
            text={"2/2"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <p className="ov">Past works</p>
            {featuredWorks.map((item, index) => {
              return (
                <>
                  <Input
                    contClass="list-input-cont cc1"
                    extraClass="list-input"
                    label="Title"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={item.title}
                    onChange={(e) => {
                      handleInputChange1(index, "title", e.target.value);
                      handleFieldChange(`tt${index}`, e.target.value);
                    }}
                    error={errors[`tt${index}`]}
                    errorText={errors[`tt${index}`]}
                  />
                  <div
                    className={`img-upload-cont ${
                      item.fileName ? "img-upload-cont1" : ""
                    } ${errors[`dd${index}`] ? "img-cont-error" : ""}`}
                    onClick={() => {
                      // @ts-ignore

                      // @ts-ignore
                      if (item.link === "") {
                        // @ts-ignore
                        fileInputRefs[index].click();
                      }
                    }}
                    style={{ cursor: item.link ? "not-allowed" : "pointer" }}
                  >
                    <input
                      type="file"
                      // @ts-ignore
                      ref={(el) => (fileInputRefs[index] = el)}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(index, e)}
                    />

                    <div className="upp-nav">
                      <p>Upload Document</p>
                      {isUploading ? (
                        <ActivityIndicator size="24px" color="#a7fc00" />
                      ) : item.fileName ? (
                        <img src={roundedCheck} />
                      ) : (
                        <></>
                      )}
                    </div>

                    <img src={item.fileName ? uploadedImg : uploadImg} alt="" />
                    <p>
                      {" "}
                      {item.fileName
                        ? "Image Uploaded Successfully"
                        : "Upload Image"}
                    </p>
                    <span
                      onClick={() => {
                        if (uploadError) {
                          // tryFileUploadAgain();
                        }
                      }}
                    >
                      {item.fileName
                        ? `${item.fileName}`
                        : uploadError
                        ? "Try again"
                        : "(375*150 recommended)"}
                    </span>
                    {item.fileName && (
                      <div className="bbb-nav">
                        <div className="nn-wrap">
                          <img src={gallary} alt="" />
                          <p>{item.fileName}</p>
                        </div>

                        <img
                          src={trash}
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeUploadedFile(index);
                            console.log("sg");
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <Input
                    contClass="list-input-cont cc"
                    extraClass={`list-input ${
                      item.document != "" ? "cus" : ""
                    }`}
                    label="or enter document link"
                    contStyle={{
                      height: 64,
                      // cursor: item.document != "" ? "not-allowed" : "auto",
                      backgroundColor: "#0d0d0d",
                    }}
                    value={item.link}
                    labelStyle={{ fontSize: 14 }}
                    disabled={item.document != ""}
                    onChange={(e) => {
                      handleInputChange1(index, "link", e.target.value);
                      handleFieldChange(`ll${index}`, e.target.value);
                      handleFieldChange(`dd${index}`, e.target.value);
                    }}
                    error={errors[`ll${index}`]}
                    errorText={errors[`ll${index}`]}
                  />
                </>
              );
            })}

            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddMore();
                }}
              >
                <img src={plus} alt="" /> Add more
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Achievement</p>
            {archievements.map((item, index) => {
              return (
                <>
                  <Input
                    contClass="list-input-cont cc1"
                    extraClass="list-input"
                    label="Title"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={item.title}
                    onChange={(e) => {
                      handleInputChange1(index, "title", e.target.value);
                      handleFieldChange(`arct${index}`, e.target.value);
                    }}
                    error={errors[`arct${index}`]}
                    errorText={errors[`arct${index}`]}
                  />
                  <div
                    className={`img-upload-cont ${
                      item.fileName ? "img-upload-cont1" : ""
                    } ${errors[`arcd${index}`] ? "img-cont-error" : ""}`}
                    onClick={() => {
                      if (item.link === "") {
                        // @ts-ignore
                        fileInputRefs1[index].click();
                      }
                    }}
                    style={{ cursor: item.link ? "not-allowed" : "pointer" }}
                  >
                    <input
                      type="file"
                      // @ts-ignore
                      ref={(el) => (fileInputRefs1[index] = el)}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect1(index, e)}
                    />

                    <div className="upp-nav">
                      <p>Upload Document</p>
                      {isUploading && archievements[index] ? (
                        <ActivityIndicator size="24px" color="#a7fc00" />
                      ) : item.fileName ? (
                        <img src={roundedCheck} />
                      ) : (
                        <></>
                      )}
                    </div>

                    <img src={item.fileName ? uploadedImg : uploadImg} alt="" />
                    <p>
                      {" "}
                      {item.fileName
                        ? "Image Uploaded Successfully"
                        : "Upload Image"}
                    </p>
                    <span
                      onClick={() => {
                        if (uploadError) {
                          // tryFileUploadAgain();
                        }
                      }}
                    >
                      {item.fileName
                        ? `${item.fileName}`
                        : uploadError
                        ? "Try again"
                        : "(375*150 recommended)"}
                    </span>
                    {item.fileName && (
                      <div className="bbb-nav">
                        <div className="nn-wrap">
                          <img src={gallary} alt="" />
                          <p>{item.fileName}</p>
                        </div>

                        <img
                          src={trash}
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeUploadedFile1(index);
                            console.log("sg");
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <Input
                    contClass="list-input-cont cc"
                    extraClass={`list-input ${
                      item.document != "" ? "cus" : ""
                    }`}
                    label="or enter document link"
                    contStyle={{
                      height: 64,
                      // cursor: item.document != "" ? "not-allowed" : "auto",
                      backgroundColor: "#0d0d0d",
                    }}
                    value={item.link}
                    labelStyle={{ fontSize: 14 }}
                    disabled={item.document != ""}
                    onChange={(e) => {
                      handleInputChange(index, "link", e.target.value);
                      handleFieldChange(`arcl${index}`, e.target.value);
                      handleFieldChange(`arcd${index}`, e.target.value);
                    }}
                    error={errors[`arcl${index}`]}
                    errorText={errors[`arcl${index}`]}
                  />
                </>
              );
            })}
            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddMore();
                }}
              >
                <img src={plus} alt="" /> Add company achievment
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Company contact</p>
            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Email Address"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleFieldChange("email", e.target.value);
                  }}
                  error={errors.email}
                  errorText={errors.email}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="LinkedIn URL"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={LinkedInUrl}
                  onChange={(e) => {
                    setLinkedInUrl(e.target.value);
                    handleFieldChange("LinkedInUrl", e.target.value);
                  }}
                  error={errors.LinkedInUrl}
                  errorText={errors.LinkedInUrl}
                />
              </div>
            </div>
          </div>

          <div className="std-cont">
            <div className="std-btn">Save to draft</div>
            <button
              className={`c-btn ${loading ? "c-disabled" : ""}`}
              disabled={loading}
              // onClick={handleFormSubmit}
            >
              {loading ? (
                <div className="dot-loader">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                "Send request"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateOwnerSpotlight2;
