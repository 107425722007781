import React, { useState, useEffect, useRef } from "react";
import SideNav from "../../../Components/SideNav";
import ProfileNav from "../../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import ProgressRing from "../../../Components/ProgressRing";
import chevBack from "../../../assets/ion_chevron-back.svg";
import ImageVideoUploader from "../../../Components/ImageVideoUploader";
import plus from "../../../assets/plus.svg";
import Input from "../../../Components/Input";
import { useFileUpload } from "../../../context/uploadFile";
import ActivityIndicator from "../../../Components/ActivityIndicator";
import uploadedImg from "../../../assets/uploadedImg.svg";
import gallary from "../../../assets/gallery.svg";
import trash from "../../../assets/trash.svg";
import roundedCheck from "../../../assets/rc.svg";
import uploadImg from "../../../assets/upload.svg";
import { GetIndustries } from "../../../RequestHandlers/Auth";
import chevDown from "../../../assets/chev-down.svg";
import NumberMonthDropdown from "../../../Components/NumberMonthDropdown";
import colon from "../../../assets/colon.svg";

interface Row {
  months: string;
  roi: string;
}
interface fwork {
  title: string;
  document: string;
  link: string;
  fileName?: string;
}
function CreateTalentSpotlight() {
  const navigate = useNavigate();
  const [image, setImages] = useState([""]);
  const imgFileInputRef = useRef<HTMLInputElement[]>([]);
  const videoFileInputRef = useRef<HTMLInputElement[]>([]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const fileInputRef = useRef(null);
  const [errors, setErrors] = useState<any>({});
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fr, setFr] = useState<any>([]);
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const [videos, setVideos] = useState([""]);
  const [isFocused, setIsFocused] = useState(false);
  const [aboutTalent, setAboutTalent] = useState("");
  const [tName, setTName] = useState("");
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [industries, setIndustries] = useState<any>(null);
  const [aUnit, setAUnit] = useState("");
  const [unit, setUnit] = useState("");
  const [showFd, setShowFD] = useState(false);
  const [activeFd, setActiveFd] = useState<any>(null);
  const [isFocused1, setIsFocused1] = useState(false);
  const [fg, setFg] = useState("");
  const [usef, setUseF] = useState("");
  const [activeFr, setActiveFr] = useState<any>(null);
  const [hangedFile, setHangedFile] = useState<any>(null);
  const [skills, setSkills] = useState<string[]>(["", "", ""]);
  const [rows, setRows] = useState<Row[]>([
    { months: "", roi: "" },
    { months: "", roi: "" },
    { months: "", roi: "" },
    { months: "", roi: "" },
  ]);
  const [featuredWorks, setFeaturedWorks] = useState<fwork[]>([
    { title: "", document: "", link: "", fileName: "" },
  ]);
  const numberofmonths = [
    { name: "3 Month", value: 3 },
    { name: "6 Months", value: 6 },
    { name: "9 Months", value: 9 },
    { name: "12 Months", value: 12 },
    { name: "15 Months", value: 15 },
  ];
  const handleAddMoreImage = () => {
    setImages([...image, ""]);
  };
  const handleAddMoreVideo = () => {
    setVideos([...videos, ""]);
  };
  const handleAddSkill = () => {
    setSkills([...skills, ""]);
  };
  const handleAddMore = () => {
    setFeaturedWorks([
      ...featuredWorks,
      { title: "", document: "", link: "", fileName: "" },
    ]);
  };
  useEffect(() => {
    imgFileInputRef.current = image.map(
      (_, i) =>
        imgFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
    videoFileInputRef.current = image.map(
      (_, i) =>
        videoFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);
  const handleSkillChange = (index: number, value: string) => {
    const updatedSkills = [...skills];
    updatedSkills[index] = value;
    setSkills(updatedSkills);
  };
  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };
  const handleInputChange1 = (
    index: number,
    field: keyof fwork,
    value: string
  ) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              [field]: value,
              ...(field === "link" ? { document: "" } : {}),
              ...(field === "document" ? { link: "" } : {}),
            }
          : item
      )
    );
  };
  const handleUploadClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };
  const handleInputChange = (
    index: number,
    field: keyof Row,
    value: string
  ) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };
  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          setSelectedFile(data);
          handleFieldChange("coverImage", e.target.value);
        } else {
          setSelectedFile(null);
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };

  const formatWithComma = (value: string | number): string => {
    if (!value) return "";
    return new Intl.NumberFormat("en-US").format(Number(value));
  };

  const handleFileSelect1 = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        const data = await uploadFile(file);
        if (data.secure_url) {
          handleInputChange1(index, "document", data.secure_url);
          setFeaturedWorks((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    document: data.secure_url,
                    fileName: file.name,
                    link: "",
                  }
                : item
            )
          );
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const fileInputRefs1 = useRef<HTMLInputElement[]>([]);
  // Initialize refs
  useEffect(() => {
    fileInputRefs1.current = featuredWorks.map(
      (_, i) => fileInputRefs1.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);
  const removeUploadedFile = (index: number) => {
    setFeaturedWorks((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, document: "", fileName: "" } : item
      )
    );
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    navigate("/CreateTalentSpotlight2")
    console.log("Form submission attempted", newErrors);
    if (tName.trim() === "") {
      newErrors.tName = "Field is required";
    }
    if (selectedFile === null) {
      newErrors.coverImage = "Field is required";
    }
    if (aboutTalent.trim() === "") {
      newErrors.aboutTalent = "Field is required";
    }
    if (activeIndustry === null) {
      newErrors.activeIndustry = "Field is required";
    }
    if (activeFr === null) {
      newErrors.activeFr = "Field is required";
    }
    if (aUnit.trim() === "") {
      newErrors.aUnit = "Field is required";
    } else if (isNaN(Number(aUnit))) {
      newErrors.aUnit = "Units must be a number";
    }
    if (fg.trim() === "") {
      newErrors.fg = "Field is required";
    }
    if (unit.trim() === "") {
      newErrors.unit = "Field is required";
    } else if (isNaN(Number(unit))) {
      newErrors.unit = "Unit price must be a number";
    }
    if (usef.trim() === "") {
      newErrors.usef = "Field is required";
    }
    // Row-specific validations
    rows.slice(0, 4).forEach((item, index) => {
      if (item.months === "") {
        newErrors[`m${index}`] = "Field is required";
      }
      if (item.roi === "") {
        newErrors[`roi${index}`] = "Field is required";
      }
    });
    skills.slice(0, 4).forEach((item, index) => {
      if (item.trim() === "") {
        newErrors[`skills${index}`] = "Field is required";
      }
    });
    // featuredWorks.forEach((item, index) => {
    //   if (item.title.trim() === "") {
    //     newErrors[`title${index}`] = "Field is required";
    //   }

    //   if (!item.document && !item.link) {
    //     newErrors[`docOrLink${index}`] = "Either a document or a link is required";
    //   }
    // });

    // If errors exist, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, proceed with form submission
    console.log("Form submitted successfully:", {
      tName,
      uploadedFileUrl,
      aboutTalent,
      activeIndustry,
      activeFr,
      //   uploadedFile,
      aUnit,
      fg,
      unit,
      usef,
      rows,
      skills,
      //   featuredWorks,
    });

    // Clear errors and navigate
    setErrors({});
    // navigate("/ListTalent2", {
    //   state: {
    //     data: {
    //       name: tName,
    //       img: uploadedFileUrl,
    //       aboutTalent: aboutTalent,
    //       activeIndustry: activeIndustry,
    //       fundingRound: activeFr,
    //       aUnit: aUnit,
    //       fg: fg,
    //       unit: unit,
    //       use: usef,
    //       rows: rows,
    //       skills: skills,
    //     //   featuredWorks: featuredWorks,
    //     },
    //   },
    // });
  };
  return (
    <div className="dash-body">
      <SideNav activeTab="Spotlight" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>Create Spotlight Request</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={50}
            text={"1/2"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <Input
              contClass="list-input-cont"
              extraClass="list-input"
              label="Talent name"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              value={tName}
              onChange={(e) => {
                setTName(e.target.value);
                handleFieldChange("tName", e.target.value);
              }}
              error={errors.tName}
              errorText={errors.tName}
            />
            <div
              className={`img-upload-cont ${
                selectedFile ? "img-upload-cont1" : ""
              } ${errors.coverImage ? "img-cont-error" : ""}`}
              onClick={() => {
                handleUploadClick();
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              {selectedFile || isUploading ? (
                <div className="upp-nav">
                  <p>Cover Image (related to the talent)</p>
                  {isUploading ? (
                    <ActivityIndicator size="24px" color="#a7fc00" />
                  ) : (
                    <img src={roundedCheck} />
                  )}
                </div>
              ) : (
                <></>
              )}
              <img src={selectedFile ? uploadedImg : uploadImg} alt="" />
              <p>
                {" "}
                {selectedFile ? "Image Uploaded Successfully" : "Upload Image"}
              </p>
              <span>
                {selectedFile
                  ? `${selectedFile?.original_filename}.${selectedFile?.format}`
                  : uploadError
                  ? "Try again"
                  : "(375*150 recommended)"}
              </span>
              {selectedFile && (
                <div className="bbb-nav">
                  <div className="nn-wrap">
                    <img src={gallary} alt="" />
                    <p>
                      {selectedFile?.original_filename}.{selectedFile?.format}
                    </p>
                  </div>
                  <img
                    src={trash}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedFile(null);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={`abt-talent-cont ${
                errors.aboutTalent ? "img-cont-error" : ""
              }`}
            >
              <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                About Talent
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={aboutTalent}
                onChange={(e) => {
                  setAboutTalent(e.target.value);
                  adjustHeight(e.target);
                  handleFieldChange("aboutTalent", e.target.value);
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => setIsFocused(aboutTalent != "" ? true : false)}
              />
            </div>
            {errors.aboutTalent && (
              <p className="error-text">{errors.aboutTalent}</p>
            )}
          </div>

          <div className="content-cont">
            <p className="ov">Gallery</p>
            <div className="gallary-wrap">
              <div className="gal-hold">
                {image.map((item, index) => {
                  return (
                    <ImageVideoUploader
                      topText="Upload Image"
                      fileInputRef={imgFileInputRef}
                      onContainerClick={() => {
                        // @ts-ignore
                        imgFileInputRef[index].click();
                      }}
                    />
                  );
                })}
                <div className="inc-btn-cont">
                  <div
                    className="add-btn"
                    onClick={() => {
                      handleAddMoreImage();
                    }}
                  >
                    <img src={plus} alt="" /> Add Image
                  </div>
                </div>
              </div>
              <div className="gal-hold">
                <ImageVideoUploader
                  topText="Upload Video"
                  fileInputRef={videoFileInputRef}
                />
                <div className="inc-btn-cont">
                  <div
                    className="add-btn"
                    onClick={() => {
                      handleAddMoreImage();
                    }}
                  >
                    <img src={plus} alt="" /> Add Video
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Talent’s unique skills</p>
            <div className="skills-cont">
              {skills.map((item, index) => {
                return (
                  <div className="skill-input" key={index}>
                    <Input
                      contClass="list-input-cont"
                      extraClass="list-input"
                      label="Skill"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item}
                      onChange={(e) => {
                        handleSkillChange(index, e.target.value);
                        handleFieldChange(`skills${index}`, e.target.value);
                      }}
                      error={!!errors[`skills${index}`]} // Add error highlighting
                      errorText={errors[`skills${index}`]} // Show the error text if any
                    />
                  </div>
                );
              })}
            </div>

            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddSkill();
                }}
              >
                <img src={plus} alt="" /> Add skill
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Featured work </p>
            {featuredWorks.map((item, index) => {
              return (
                <>
                  <Input
                    contClass="list-input-cont cc1"
                    extraClass="list-input"
                    label="Title"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={item.title}
                    onChange={(e) => {
                      handleInputChange1(index, "title", e.target.value);
                      handleFieldChange(`tt${index}`, e.target.value);
                    }}
                    error={errors[`tt${index}`]}
                    errorText={errors[`tt${index}`]}
                  />
                  <div
                    className={`img-upload-cont ${
                      item.fileName ? "img-upload-cont1" : ""
                    } ${errors[`dd${index}`] ? "img-cont-error" : ""}`}
                    onClick={() => {
                      // @ts-ignore

                      // @ts-ignore
                      if (item.link === "") {
                        // @ts-ignore
                        fileInputRefs1[index].click();
                      }
                    }}
                    style={{ cursor: item.link ? "not-allowed" : "pointer" }}
                  >
                    <input
                      type="file"
                      // @ts-ignore
                      ref={(el) => (fileInputRefs1[index] = el)}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect1(index, e)}
                    />
                    {item.fileName || isUploading ? (
                      <div className="upp-nav">
                        {item.fileName ? <p>Cover Image</p> : <div></div>}
                        {isUploading ? (
                          <ActivityIndicator size="24px" color="#a7fc00" />
                        ) : (
                          <img src={roundedCheck} />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <img src={item.fileName ? uploadedImg : uploadImg} alt="" />
                    <p>
                      {" "}
                      {item.fileName
                        ? "Image Uploaded Successfully"
                        : "Upload Image"}
                    </p>
                    <span
                      onClick={() => {
                        if (uploadError) {
                          // tryFileUploadAgain();
                        }
                      }}
                    >
                      {item.fileName
                        ? `${item.fileName}`
                        : uploadError
                        ? "Try again"
                        : "(375*150 recommended)"}
                    </span>
                    {item.fileName && (
                      <div className="bbb-nav">
                        <div className="nn-wrap">
                          <img src={gallary} alt="" />
                          <p>{item.fileName}</p>
                        </div>

                        <img
                          src={trash}
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeUploadedFile(index);
                            console.log("sg");
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <Input
                    contClass="list-input-cont cc"
                    extraClass={`list-input ${
                      item.document != "" ? "cus" : ""
                    }`}
                    label="or enter document link"
                    contStyle={{
                      height: 64,
                      // cursor: item.document != "" ? "not-allowed" : "auto",
                      backgroundColor: "#0d0d0d",
                    }}
                    value={item.link}
                    labelStyle={{ fontSize: 14 }}
                    disabled={item.document != ""}
                    onChange={(e) => {
                      handleInputChange1(index, "link", e.target.value);
                      handleFieldChange(`ll${index}`, e.target.value);
                      handleFieldChange(`dd${index}`, e.target.value);
                    }}
                    error={errors[`ll${index}`]}
                    errorText={errors[`ll${index}`]}
                  />
                </>
              );
            })}

            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddMore();
                }}
              >
                <img src={plus} alt="" /> Add more
              </div>
            </div>
          </div>
          <div className="std-cont">
            <div className="std-btn">Save to draft</div>
            <div
              className="c-btn"
              // onClick={() => {
              //   navigate("/ListTalent2", { state: { type: type } });
              // }}
              onClick={handleFormSubmit}
            >
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTalentSpotlight;
