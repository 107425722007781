import React, { createContext, useContext, useState } from "react";

interface FileUploadContextProps {
  uploadFile: (file: File) => Promise<any>;
  isUploading: boolean;
  uploadError: string | null;
}

const FileUploadContext = createContext<FileUploadContextProps | undefined>(
  undefined
);

const cloud_name = "daqjecxhy";
const cloud_key = "868378813212551";
const preset_name = "stable_blocks";
// Provider Component
export const FileUploadProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const uploadFile = async (file: File): Promise<any> => {
    setIsUploading(true);
    setUploadError(null);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_name);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloud_name}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error?.message || "Failed to upload");
      }

      return data; // Return the uploaded file data
    } catch (err: any) {
      const errorMessage = err.message || "Something went wrong";
      setUploadError(errorMessage);
      throw errorMessage; // Rethrow for consumer handling
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <FileUploadContext.Provider
      value={{ uploadFile, isUploading, uploadError }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

// Hook to consume the context
export const useFileUpload = () => {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error("useFileUpload must be used within a FileUploadProvider");
  }
  return context;
};
